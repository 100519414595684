import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "@apollo/client/link/http";
import { ApolloClient, InMemoryCache, from } from "@apollo/client";

import config from './config';
import { getToken, logout } from "./services/AuthService";

const urlLink = new HttpLink({ uri: config.backend.GraphQL.UrlLink });

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, extensions, locations, path }) => {
            console.error(`[GraphQL] Message: ${message}, Location: ${locations}, Path: ${path}`);
            if (extensions?.code === 'UNAUTHENTICATED') logout();
        });
    if (networkError) console.error(`[Network] ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
    const token = getToken();
    return {
        headers: {
            ...headers,
            authorization: token,
        },
    };
});


const createClient = new ApolloClient({
    link: from([ errorLink, authLink, urlLink ]),
    cache: new InMemoryCache(),
});

export {
    createClient,
}
