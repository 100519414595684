import React, { Fragment, useState } from 'react';
import { orderBy } from 'lodash';
import Numeral from 'numeral';

import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';

import { MouseOverPopover } from '../../../components';

import { EnhancedTableHead } from '../EnhancedTableHead/EnhancedTableHead';
import { EnhancedTableToolbar } from '../EnhancedTableToolbar/EnhancedTableToolbar';

import { EnhancedTableProps } from '../AwardHistory.schema';

const DEFAULT_ROWS_PER_PAGE = 10;

export const EnhancedTable: React.FC<EnhancedTableProps> = props => {
    const classes = useEnhancedTableStyles();
    const { isLoading, awardedInvoices, totalAwardedAmount, totalPremiumAmount } = props;
    const [order, setOrder] = useState<'asc' | 'desc' | boolean>('desc');
    const [orderCol, setorderCol] = useState<string>('amount_grossvat');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);

    const rowsCurrentPage = Math.min(rowsPerPage, awardedInvoices.length - page * rowsPerPage);
    const emptyRows = rowsCurrentPage < DEFAULT_ROWS_PER_PAGE ? DEFAULT_ROWS_PER_PAGE - rowsCurrentPage : 0;

    const handleRequestSort = (event: React.ChangeEvent<HTMLInputElement>, property: string) => {
        setOrder(orderCol === property && order === 'desc' ? 'asc' : 'desc');
        setorderCol(property);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const createSkeletonRows = (rowCount: number) => {
        const skeletonRows: JSX.Element[] = [];
        for (let i = 0; i < rowCount; i++) skeletonRows.push(<Skeleton key={i} className={classes.skeletonRow} />);
        return skeletonRows;
    };

    return (
        <Fragment>
            <EnhancedTableToolbar
                isLoading={isLoading}
                totalAwardedAmount={totalAwardedAmount}
                totalPremiumAmount={totalPremiumAmount}
                daysExtended={awardedInvoices.length && awardedInvoices[0].days_extended}
                aprRate={awardedInvoices.length && awardedInvoices[0].apr_rate}
            />
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <EnhancedTableHead order={order} orderCol={orderCol} onRequestSort={handleRequestSort} />
                    {awardedInvoices.length ? (
                        <TableBody>
                            {orderBy(awardedInvoices, [orderCol], [order])
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(row => (
                                    <TableRow hover tabIndex={-1} key={row.invoice_id}>
                                        <TableCell>
                                            {row.invoice_id} <MouseOverPopover content={row.description} />
                                        </TableCell>
                                        <TableCell align="right">€{Numeral(row.amount).format('#,##0.00')}</TableCell>
                                        <TableCell align="right">€{Numeral(row.vat_amount).format('#,##0.00')}</TableCell>
                                        <TableCell align="right">€{Numeral(row.amount_grossvat).format('#,##0.00')}</TableCell>
                                        <TableCell align="right">{row.payment_due_date}</TableCell>
                                        <TableCell align="right" style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}>
                                            {`€${Numeral(row.premium_amount).format('#,##0.00')}`}
                                        </TableCell>
                                        <TableCell align="right">{`€${Numeral(row.new_gross_amount).format('#,##0.00')}`}</TableCell>
                                        <TableCell align="right">{row.new_due_date}</TableCell>
                                    </TableRow>
                                ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={8} />
                                </TableRow>
                            )}
                        </TableBody>
                    ) : (
                        <TableBody className={classes.emptyTableBody}>
                            <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell className={classes.emptyTableInfoCell} colSpan={8}>
                                    {isLoading ? (
                                        createSkeletonRows(10)
                                    ) : (
                                        <Typography className={classes.emptyTableInfo}>No history of extended invoices found.</Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </div>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={awardedInvoices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Fragment>
    );
};

const useEnhancedTableStyles = makeStyles(theme => ({
    tableWrapper: {
        overflowX: 'auto',
    },
    table: {
        minWidth: '750px',
    },
    emptyTableBody: {
        background: '#F6F6F6',
    },
    emptyTableInfoCell: {
        border: 'none',
        textAlign: 'center',
        fontSize: '1.25rem',
    },
    emptyTableInfo: {
        background: 'white',
        width: theme.spacing(64),
        padding: theme.spacing(2),
        borderRadius: '0.3rem',
        margin: 'auto',
    },
    skeletonRow: {
        margin: theme.spacing(1, 0),
        height: '37px !important',
    },
}));
