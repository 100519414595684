import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';

export const MouseOverPopover: React.FC<{ content: string }> = ({ content }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    function handlePopoverOpen(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handlePopoverClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    return (
        <span>
            <span aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                <InfoIcon className={classes.icon} />
            </span>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{ paper: classes.paper }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                onClose={handlePopoverClose}
                disableRestoreFocus>
                <Typography>
                    <strong>Description:</strong> {content}
                </Typography>
            </Popover>
        </span>
    );
};

const useStyles = makeStyles(theme => ({
    icon: {
        verticalAlign: 'bottom',
        fontSize: '1.25rem',
        '&:hover': {
            opacity: 1,
        },
        opacity: 0.5,
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));
