import React from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { NavItemProps } from '../NavDrawer.schema';

export const NavItem: React.FC<NavItemProps> = props => {
    const { url, icon, label } = props;
    const classes = useNavItemStyles();
    return (
        <NavLink
            to={url}
            className={classes.navLink}
            style={({ isActive }) => {
                if (isActive) {
                    return { backgroundColor: 'rgba(0, 0, 0, 0.14)' };
                }
                return;
            }}>
            <ListItem button>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
            </ListItem>
        </NavLink>
    );
};

const useNavItemStyles = makeStyles<Theme>(theme => ({
    navLink: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
    },
}));
