import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const cancelOffer = gql`
    mutation cancelOffer($company_id: String!) {
        cancelOffer(company_id: $company_id)
    }
`;

function useCancelOffer() {
    // Destructure the mutation
    const [CancelOffer] = useMutation(cancelOffer);
    // Return the value to continue using it as is in the other files
    return CancelOffer;
}

export { useCancelOffer };
