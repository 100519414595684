import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

import { LoadAwareStatProps } from '../AwardHistory.schema';

export const LoadAwareStat: React.FC<LoadAwareStatProps> = props => {
    const { title, children, isLoading } = props;
    const classes = useToolbarStyles();
    return (
        <Box textAlign="center" width={title === 'APR' ? '6rem' : '9rem'}>
            <Typography color="inherit" variant="caption">
                {title}
            </Typography>
            {isLoading ? <Skeleton className={classes.skeletonStat} /> : <Typography>{children}</Typography>}
        </Box>
    );
};

const useToolbarStyles = makeStyles(theme => ({
    skeletonStat: {
        margin: theme.spacing(0.5, 2),
        height: theme.spacing(2),
    },
}));
