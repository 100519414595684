import React, { useState, useEffect } from 'react';
import Numeral from 'numeral';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import ScheduleIcon from '@material-ui/icons/ScheduleRounded';

import { MarketStat } from '../MarketStat/MarketStat';

import { MarketCardProps } from '../CustomerMarketSummary.schema';

export const MarketCard: React.FC<MarketCardProps> = props => {
    const { market, navigateToMarket } = props;
    const classes = useMarketCardStyles();
    const offerLive = !!market.days_extended_offer;
    const [marketCloseCountdown, setMarketCloseCountdown] = useState<string | false>();

    useEffect(() => {
        function calculateCountdown(endDate: Date) {
            let diff = (Date.parse(String(endDate)) - Date.parse(String(new Date()))) / 1000;
            if (!endDate || diff <= 0) return false;

            const timeLeft = { days: 0, hours: 0, min: 0 };

            if (diff >= 86400) {
                timeLeft.days = Math.floor(diff / 86400);
                diff -= timeLeft.days * 86400;
            }
            if (diff >= 3600) {
                timeLeft.hours = Math.floor(diff / 3600);
                diff -= timeLeft.hours * 3600;
            }
            if (diff >= 60) {
                timeLeft.min = Math.floor(diff / 60);
                diff -= timeLeft.min * 60;
            }
            return `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.min}m`;
        }

        setMarketCloseCountdown(calculateCountdown(market.next_clear_time));
    }, [market.next_clear_time]);

    return (
        <Card className={classes.card}>
            <CardActionArea onClick={() => navigateToMarket(market.company_id)}>
                <CardContent>
                    <Box className={classes.marketStatHeaderContainer}>
                        {marketCloseCountdown ? (
                            <Box className={classes.marketCloseCountdownContainer}>
                                <Box className={classes.marketCloseCountdown}>
                                    <ScheduleIcon />
                                    <Typography>{marketCloseCountdown}</Typography>
                                </Box>
                                <Typography className={classes.marketCountdownTitle}>Market Ends</Typography>
                            </Box>
                        ) : (
                            <Box className={classes.marketCloseCountdownContainer}>
                                <Typography className={classes.marketCountdownTitle}>Market Closed</Typography>
                            </Box>
                        )}
                        <Typography variant="h5" component="h2">
                            {market.customer_name}
                        </Typography>
                        <Typography className={offerLive ? classes.offerLivePulseContainer : classes.offerDisabledPulseContainer}>
                            <span className={offerLive ? classes.offerLivePulseIcon : classes.offerDisabledPulseIcon} />
                            OFFER LIVE
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
            <CardContent>
                <Box className={classes.marketStatsContainer}>
                    <Box className={classes.marketStatInnerContainer}>
                        <MarketStat disabled={false} label="Eligible Invoices" content={`€${Numeral(market.total_eligible_amount).format('#,##0.00')}`} />
                        <MarketStat label="Invoices Offered" disabled={!offerLive} content={`€${Numeral(market.total_selected_amount).format('#,##0.00')}`} />
                    </Box>
                    <Box className={classes.marketStatInnerContainer}>
                        <MarketStat label="Extension Offered" disabled={!offerLive} content={`${market.days_extended_offer} Days`} />
                        <MarketStat label="APR" disabled={!offerLive} content={`${ market.apr_offer && market.apr_offer.toFixed(2)}%`} />
                    </Box>
                    <Box className={classes.marketStatInnerContainer}>
                        <MarketStat label="Total Premium" disabled={!offerLive} content={`€${Numeral(market.total_premium_amount).format('#,##0.00')}`} />
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

const useMarketCardStyles = makeStyles<Theme>(theme => ({
    card: {
        width: '98%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.35)',
        margin: '1rem auto',
        maxWidth: '1200px',
        '&> .MuiCardActionArea-root > .MuiCardContent-root': {
            background: theme.palette.secondary.main,
            color: 'white',
        },
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px',
        },
    },
    marketStatHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        '&> h2': {
            fontSize: '1.25rem',
        },
    },
    offerLivePulseContainer: {
        background: theme.palette.primary.main,
        color: 'white',
        padding: theme.spacing(1, 2, 1, 3),
        borderRadius: '30px 0 0 30px',
        position: 'absolute',
        right: 0,
        lineHeight: '1.9',
        height: '80%',
    },
    offerLivePulseIcon: {
        width: theme.spacing(1),
        height: theme.spacing(1),
        background: 'white',
        marginRight: theme.spacing(1.75),
        animation: '$pulse 1.5s infinite',
        display: 'inline-block',
        borderRadius: '50%',
        marginBottom: '1.5px',
    },
    '@keyframes pulse': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.6)',
        },
        '70%': {
            boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)',
        },
        '100%': {
            boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
        },
    },
    offerDisabledPulseContainer: {
        background: '#f2f2f2',
        color: '#cccccc',
        padding: theme.spacing(1, 2, 1, 3),
        borderRadius: '30px 0 0 30px',
        position: 'absolute',
        right: 0,
        lineHeight: '2',
        height: '80%',
    },
    offerDisabledPulseIcon: {
        width: theme.spacing(1),
        height: theme.spacing(1),
        background: '#cccccc',
        marginRight: theme.spacing(1.75),
        display: 'inline-block',
        borderRadius: '50%',
        marginBottom: '1.5px',
    },
    marketStatInnerContainer: {
        '&>.MuiTypography-body1:first-of-type': {
            marginBottom: theme.spacing(2),
        },
    },
    marketStatsContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        margin: theme.spacing(1),
        textAlign: 'center',
        '& .MuiTypography-caption': {
            fontSize: '0.9rem',
            lineHeight: '1',
        },
        '& .MuiTypography-body1': {
            fontSize: '1.25rem',
            fontWeight: '600',
            fontFeatureSettings: `'tnum' 1`,
        },
    },
    leftAlignedIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1rem',
    },
    marketCloseCountdownContainer: {
        display: 'flex',
        position: 'absolute',
        height: '2rem',
        width: '16rem',
        left: 'calc(50% - 8rem)',
        background: 'white',
        color: theme.palette.text.primary,
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        margin: '0 auto',
        '& svg': {
            fontSize: '1.25rem',
            marginRight: '0.5rem',
        },
        '& p': {
            fontSize: '1rem',
            lineHeight: '1',
        },
    },
    marketCloseCountdown: {
        display: 'flex',
        alignItems: 'center',
    },
    marketCountdownTitle: {
        fontWeight: 600,
    },
}));
