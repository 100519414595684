import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';

import { Footer } from './components/';
import { Login } from './pages/';
import ProtectedRoutes from './ProtectedRoutes';
import { verifyLogin } from './services/AuthService';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | undefined>(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadCredentials = async () => {
            setIsLoggedIn(await verifyLogin());
            setIsLoading(false);
        };
        loadCredentials();
    }, []);

    return (
        <Box display="flex" overflow="hidden" minHeight="100vh" position="relative">
            {isLoggedIn ? <ProtectedRoutes /> : !isLoading && <Login handleLoginSuccess={() => setIsLoggedIn(true)} />}
            <Footer />
        </Box>
    );
};

export default App;
