import { useEffect, useRef } from 'react';

interface SavedCallback {
    current: Function | undefined;
}

export const useInterval = (callback, delay) => {
    const savedCallback: SavedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current!();
        }
        if (delay !== null) {
            let interval = setInterval(tick, delay);
            return () => clearInterval(interval);
        }
    }, [delay]);
};
