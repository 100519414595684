import React, { useEffect, Fragment } from 'react';
import { Route, Routes as ReactRouterRoutes, useNavigate, useLocation, LoaderFunction, redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import { getUserType, getUserCompanyIdList, getUserTermsCondsFlag, getUserId } from './services/AuthService';
import { ActiveInvoices, AwardHistory, CustomerMarketSummary, TermsAndConditions, VendorMarketSettings } from './pages/';
import { NavDrawer } from './components/';

const validateCompanyAccess = (): boolean => {
    const currentRoute = window.location.pathname.split('?')[0];
    const companyId = currentRoute.replace(/\D+/g, '');
    if (companyId) {
        const companyIdList: any = getUserCompanyIdList();
        return companyIdList.includes(companyId);
    }
    return true;
};

const validateCustomerAccess = (): boolean => getUserType() === 'Customer' && validateCompanyAccess();

const validateVendorAccess = (): boolean => getUserType() === 'Vendor' && validateCompanyAccess();

const vendorLoader = async () => {
    if (!getUserId() || !validateVendorAccess()) {
        return redirect('/');
    }
    return null;
};

const customerLoader = async () => {
    if (!getUserId() || !validateCustomerAccess()) {
        return redirect('/');
    }
    return null;
};

const Routes: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log('location', location);
    console.log('location.pathname', location.pathname);

    useEffect(() => {
        if (location.pathname === '/') {
            const userType = getUserType();
            const companyIdList: any = getUserCompanyIdList();

            if (userType === 'Vendor') navigate('/vendor/markets');
            else if (userType === 'Customer') {
                if (!getUserTermsCondsFlag()) navigate('/terms-and-conditions');
                else if (userType === 'Customer' && companyIdList.length > 1) navigate('/customer/markets');
                else navigate(`/customer/${companyIdList[0]}/invoices`);
            } else console.error('User Type invalid:', userType);
        }
    }, []);

    return (
        <Fragment>
            <NavDrawer />
            <Box width="100%">
                <ReactRouterRoutes>
                    <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                    <Route loader={customerLoader} path="/customer/:companyId/invoices" element={<ActiveInvoices />} />
                    <Route loader={customerLoader} path="/customer/:companyId/history" element={<AwardHistory />} />
                    <Route loader={customerLoader} path="/customer/markets" element={<CustomerMarketSummary />} />
                    <Route loader={vendorLoader} path="/vendor/markets" element={<VendorMarketSettings />} />
                    <Route loader={vendorLoader} path="/vendor/:companyId/invoices" element={<ActiveInvoices />} />
                    <Route loader={vendorLoader} path="/vendor/:companyId/history" element={<AwardHistory />} />
                </ReactRouterRoutes>
            </Box>
        </Fragment>
    );
};

export default Routes;
