const mock = {
    gateway: 'https://fss-gateway.c2fo.com',
    backend: {
        GraphQL: {
            UrlLink: 'https://fp9hhwlugb.execute-api.eu-central-1.amazonaws.com/dev/graphql',
        },
        C2FOAuthUrl: 'https://t2rikmpiq1.execute-api.eu-central-1.amazonaws.com/uat',
    },
    frontend: {
        App: {
            Title: 'C2FO AR Extend - Demo',
        },
    },
};

const stage = {
    gateway: 'https://fss-gateway.c2fo.com',
    backend: {
        GraphQL: {
            UrlLink: ' https://m3r7y0bioh.execute-api.eu-central-1.amazonaws.com/graphql',
        },
        C2FOAuthUrl: 'https://t2rikmpiq1.execute-api.eu-central-1.amazonaws.com/uat',
    },
    frontend: {
        App: {
            Title: '[UAT] C2FO AR Extend',
        },
    },
};

const prod = {
    gateway: 'https://gateway.c2fo.com',
    backend: {
        GraphQL: {
            UrlLink: 'https://5sx1oo301c.execute-api.eu-central-1.amazonaws.com/graphql',
        },
        // C2FOAuthUrl: 'https://t2rikmpiq1.execute-api.eu-central-1.amazonaws.com/uat',
        C2FOAuthUrl: 'https://t2rikmpiq1.execute-api.eu-central-1.amazonaws.com/prod',
    },
    frontend: {
        App: {
            Title: 'C2FO AR Extend',
        },
    },
};

const config = import.meta.env.MODE === 'production' ? prod : stage;

export default {
    // Add common config values here
    ...config,
};
