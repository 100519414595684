import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export const Footer: React.FC = () => {
    const classes = useStyles();
    const currentYear = new Date().getFullYear();

    return (
        <Box className={classes.footer}>
            <Typography className={classes.footerText}>© {currentYear} Pollen, Inc. All rights reserved | Patents Pending</Typography>
            <Link className={classes.footerLink} target="_blank" href="https://c2fo.com/privacy">
                Privacy Policy
            </Link>
            <Link className={classes.footerLink} href="/terms-and-conditions">
                Terms & Conditions
            </Link>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    footer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        margin: 'auto',
        marginBottom: theme.spacing(2),
        position: 'absolute',
        width: '560px',
        bottom: 0,
        left: 'calc(50% - 280px)',
    },
    footerLink: {
        fontSize: '0.75rem',
        textAlign: 'center',
        color: theme.palette.secondary.main,
    },
    footerText: {
        fontSize: '0.75rem',
        textAlign: 'center',
    },
}));
