import { useLoaderData, useNavigate } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { getUserType, getUserTermsCondsFlag, logout, setUserTermsCondsFlag, getUserId } from '../../services/AuthService';
import { useAcceptTermsConditions as acceptTermsConditionsMutation } from "../../services/graphql";

export const TermsAndCondition = () => {
    const navigate = useNavigate();
    const classes = useStyles();

    // Only display agree/decline buttons below the T&Cs if user has not yet accepted terms
    const showAgreementButtons: boolean = getUserType() === 'Customer' && !getUserTermsCondsFlag();
    const acceptTermsConditions = acceptTermsConditionsMutation();

    const handleAcceptTerms = async () => {
        acceptTermsConditions({
            variables: {
                uuid: getUserId(),
            }
        });
        // TODO use value from data.acceptTermsConds.terms_conds_flag above to update the local storage
        setUserTermsCondsFlag({ userTermsCondsFlag: true });
        navigate('/');
    };

    const handleDeclineTerms = () => logout();

    return (
        <Box className={classes.termsPage}>
            <div className={classes.termsContainer}>
                <h2>C2FO SERVICE AGREEMENT</h2>
                <p className={classes.lastModifiedDate}>
                    <strong>Updated:</strong> December 24, 2019
                </p>
                <p>
                    By registering as a user of the Service, you, as an individual and as an authorized representative of the company (<strong>"You"</strong>)
                    represent and warrant that you are accessing the Service on behalf of the company whose information you provided during the registration
                    process (the <strong>"Customer"</strong>).
                </p>
                <p>
                    You hereby represent and warrant that you have the authority to legally bind the Customer, and that you are duly authorized to enter into
                    this Agreement on behalf of the Customer.
                </p>
                <p>
                    Access to and use of the Services by Supplier and its Authorized Users is conditioned upon acceptance of these General Terms and Conditions
                    (the
                    <strong> "Agreement"</strong>).
                </p>
                <p>
                    By clicking the <strong>"I AGREE"</strong> button below, you accept and consent to be bound by and further represent and warrant that this
                    Agreement creates a binding contract between C2FO Limited and the Customer.
                </p>
                <h2>GENERAL TERMS AND CONDITIONS</h2>
                <p>
                    <strong>DEFINITIONS.</strong> The following are definitions applying to this Agreement. Additional definitions applying solely to a
                    particular Attachment to this Agreement will be set forth in that Attachment.
                </p>
                <p>
                    1.1 <strong>"Affiliate"</strong> means with respect to a Party, any corporation or other entity that controls, is controlled by, or is under
                    common control with, a Party.
                </p>
                <p>
                    1.2 <strong>"AR Extension" </strong>means a component of the Service which allows for Extended Payment of Customer-approved Seller invoices.
                </p>
                <p>
                    1.3 <strong>"Authorised User"</strong> means a user who is authorised by Customer to access and use the Service on behalf of Customer.
                </p>
                <p>
                    1.4 <strong>"Award File"</strong> means an electronic file compiled of awarded offers of additional premium from Customer to a Seller for
                    Extended Payment of Authorized Customer-approved invoices resulting in premiums to face value, as applicable.
                </p>
                <p>
                    1.5 <strong>"Business Day"</strong> means any day other than a Saturday, Sunday or a day on which commercial banks are required or
                    authorised to be closed by the applicable government or regulatory authority.
                </p>
                <p>
                    1.6 <strong>"C2FO Market Closing"</strong> means the time identified in the Service at which the working capital market closes, and the
                    Extended Payment award status is confirmed during each local business day.
                </p>
                <p>
                    1.7 <strong>"Confidential Information"</strong> means:
                </p>
                <p>
                    (i) With regard to C2FO, all C2FO Information, and Intellectual Property Rights related to, connected with or arising out of the Service
                    (including Market Statistics) or that Customer has access to in connection with this Agreement, and;
                </p>
                <p>
                    (ii) With regard to Customer, Customer, all Transaction Information of Customer that C2FO has access to in connection with this Agreement;
                    provided, that Market Statistics shall not be deemed to be Confidential Information of Customer.
                </p>
                <p>Notwithstanding the foregoing, Confidential Information does not include Information or Transaction Information which is:</p>
                <p>
                    (i) in the public domain at the time of the disclosure or becomes available to the public thereafter without restriction, and not as a
                    result of the act or omission of the Recipient;
                </p>
                <p>(ii) rightfully obtained by the Recipient from a third party without restriction as to disclosure;</p>
                <p>(iii) lawfully in the possession of the Recipient at the time of the disclosure;</p>
                <p>(iv) approved for release by written authorization of the Disclosing Party; or</p>
                <p>(v) developed independently and separately by the Recipient without use of the Disclosing Party's Confidential Information.</p>
                <p>
                    1.8 <strong>"Content"</strong> means any data, information or materials, specifically including Seller invoices, provided to C2FO by
                    Customer or any Authorised User, in any media known now or in the future, for distribution through the Service.
                </p>
                <p>
                    1.9 <strong>"Data Controller"</strong> means the Person who alone or jointly with others determines the purposes and means of the processing
                    of Personal Data.
                </p>
                <p>
                    1.10 <strong>"Data Processor"</strong> means a Person which processes Personal Data on behalf of the Data Controller.
                </p>
                <p>
                    1.11 <strong>"Disclosing Party"</strong> means the Party providing Confidential Information to a Recipient.
                </p>
                <p>
                    1.12 <strong>"Extended Payment"</strong> means the varying of any agreed to payment terms for a specified non-disputed Customer-approved
                    invoice resulting in extended payment in exchange for a premium on the invoiced amount through the Service which is documented in an Award
                    File.
                </p>
                <p>
                    1.13 <strong>"Fees"</strong> means all fees payable by Customer to C2FO pursuant to this Agreement.
                </p>
                <p>
                    1.14 <strong>"Force Majeure"</strong> means events or conditions beyond a Party's reasonable control, including, without limitation, acts of
                    common enemy, earthquakes, floods, fires, epidemics, terrorist attacks, embargoes, strike, fire, governmental acts or orders or
                    restrictions, acts of God, lack of internet availability beyond the demarcation of the Service (e.g. issues related to backbone peering
                    point, DNS, or root server issues), or any other reason where failure to perform is not caused by the negligence of the nonperforming Party.
                </p>
                <p>
                    1.15 <strong>"Information"</strong> means any technical, or business information in written, graphical, oral, or other tangible or
                    intangible forms, including but not limited to specifications, drawings, tools, samples, reports, compilations, records, data, drawings,
                    models, know-how, inventions, trade secrets, marketing information, customer information and financial information.
                </p>
                <p>
                    1.16 <strong>"Intellectual Property Rights"</strong> means any patent rights, copyrights, trade secrets, trade names, service marks,
                    trademarks, moral rights, know-how and any other similar rights or intangible assets recognized under any laws or international conventions,
                    and in any country or jurisdiction in the world, as intellectual creations to which rights of ownership accrue, and all registrations,
                    applications, disclosures, renewals, extensions, continuations or reissues of the foregoing now or hereafter in force.
                </p>
                <p>
                    1.17 <strong>"Malicious Code"</strong> means software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents
                    or programs.
                </p>
                <p>
                    1.18 <strong>"Market Launch"</strong> means the date on which the first transaction in the Customer marketplace is completed.
                </p>
                <p>
                    1.19 <strong>"Market Statistics"</strong> means summarised data and other derivative, aggregated, de-identified, or non-attributable
                    statistical information associated with Transaction Information (e.g. supplier registration / participation rates, segmentation analysis,
                    offer activity, invoice load trending analysis and industry, sub-industry and geographical trending analysis) that may be combined with
                    other information to optimize, construct, or provide C2FO's performance, modeling, products or services.
                </p>
                <p>
                    1.20 <strong>"Party" or "Parties"</strong> means, individually or collectively, as the case may be, C2FO and Customer and any and all
                    permitted successors and assigns.
                </p>
                <p>
                    1.21 <strong>"Person"</strong> means an individual, partnership, corporation (including a business trust), joint stock company, trust,
                    unincorporated association, joint venture, limited liability company, a government or any political subdivision or agency thereof, or any
                    other entity.
                </p>
                <p>
                    1.22 <strong>"Personal Data"</strong> means information that identifies or can be used to identify an individual (including names,
                    addresses, telephone numbers, email addresses, user names, identification numbers and other unique identifiers).
                </p>
                <p>
                    1.23 <strong>"Recipient"</strong> means the Party receiving Confidential Information from a Disclosing Party.
                </p>
                <p>
                    1.24 <strong>"Residual Data"</strong> means all data (e.g. duplicate information shared between Customer and Sellers through the Service,
                    including invoice information, discount proposals, dates and project numbers associated with purchase orders and invoices), for suppliers
                    who register and/or participate in the Services, and related Award Files.
                </p>
                <p>
                    1.25 <strong>"Sanctions"</strong> means economic, trade or financial sanctions, requirements, or embargoes imposed, administered, or
                    enforced from time to time by any Sanctions Authority.
                </p>
                <p>
                    1.26 <strong>"Sanctions Authority"</strong> means the United States (including, without limitation, the Office of Foreign Assets Control of
                    the U.S. Department of the Treasury, the U.S. Department of State, and the Bureau of Industry and Security of the U.S. Department of
                    Commerce), the United Kingdom (including, without limitation, Her Majesty's Treasury), the European Union and any EU member state, the
                    United Nations Security Council and any other relevant sanctions authority.
                </p>
                <p>
                    1.27 <strong>"Sanctioned Jurisdiction"</strong> means, at any time, a country or territory that is, or whose government is, the subject of
                    Sanctions.
                </p>
                <p>
                    1.28 <strong>"Sanctioned Person"</strong> means, at any time, (a) any Person listed in any Sanctions-related list maintained by any
                    Sanctions Authority, or (b) any Person located, organized or resident in a Sanctioned Jurisdiction.
                </p>
                <p>
                    1.29 <strong>"Seller" </strong>means, for the purposes of AR Extension, a third-party provider of goods and services to Customer that: (i)
                    is authorised by Customer to use the Service and access the Customer marketplace; and (ii) has contracted with C2FO for the purposes of
                    providing Extended Payments through the Service.
                </p>
                <p>
                    1.30<strong> "Service"</strong> branded as "<strong>C2FO</strong>" means C2FO's proprietary market-based application services platform,
                    related tools, and other services accessed and used by Customer and its Sellers to offer, accept, and document Extended Payments, including
                    any successor or replacement services offering the same or more functionality than its predecessor.
                </p>
                <p>
                    1.31 <strong>"Transaction Information"</strong> means all data, Content, and information generated or posted through use of the Service that
                    is disclosed directly or indirectly to C2FO by Customer but shall not include any information which was at the time of disclosure within an
                    enumerated exception to the definition of Confidential Information.
                </p>
                <p>
                    <strong>2. SERVICE ACCESS</strong>
                </p>
                <p>
                    2.1 <u>Use</u>. C2FO hereby grants Customer a non-exclusive, non-transferable, limited right to access and utilize the Service, subject to
                    the following restrictions: (i) Customer may use the Service solely for Customer's own internal business purposes; (ii) Customer shall not
                    (A) make any copies of all or any portion of the Service, (B) sell, sublicence, distribute, rent, lease or assign the Service to any other
                    person or entity, (C) modify, reverse engineer, decompile, disassemble, translate, alter or create derivative works based on the Service (D)
                    except for Authorised Users, permit any third party to use the Service; or (E) create Internet "links" to or from the Service, or "frame" or
                    "mirror" any content forming part of the Service, other than on Customer or its Authorised Users' own intranets or otherwise for its own
                    internal business purposes, (F) send spam or other duplicative or unsolicited messages in violation of applicable laws, (G) send or store
                    infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including material harmful to children or in
                    violation of third party privacy rights, (H) send or store material containing Malicious Code, (I) interfere with or disrupt the integrity
                    or performance of the Service or the data contained therein; (J) attempt to gain unauthorised access to the Service or its related systems
                    or networks; and (iii) Customer acknowledges and agrees that no Authorised User may be a Sanctioned Person.
                </p>
                <p>
                    2.2 <u>Access to Service</u>. C2FO shall provide Customer and each Authorised User access to the login screen where a unique login
                    credential (<strong>"User Name"</strong> and <strong>"Password"</strong>) is generated in order to access the Service. Customer is
                    responsible for maintaining the confidentiality of the User Name and Password of its Authorised Users and will be solely liable for all
                    activities that occur or arise under such User Names and Passwords. Customer shall immediately notify C2FO of any unauthorised use of any
                    User Names or Passwords. Customer agrees on its' own behalf, and to require its Authorised Users on their behalf, to access the Service in a
                    secure manner in compliance with C2FO's reasonable standards established from time to time which currently requires the use of a
                    then-current standard commercial web browser (e.g. IE10 or above Chrome) that supports TLS 1.2 or above.
                </p>
                <p>
                    <strong>3. SCOPE, AVAILABILITY AND MODIFICATIONS</strong>
                </p>
                <p>
                    3.1 <u>Scope of Service</u>. The Service is intended to enable transactions between Customer and Sellers as principals and C2FO does not act
                    for or represent in any way either the Customer or Sellers. C2FO is not a party to, third party beneficiary of, or a guarantor of
                    performance with respect to any transaction, agreement or arrangement concluded between Customer and any Sellers using the Service.
                    Specifically, (i) C2FO does not control the quality, legality or availability of the Content accessed through the Service, the terms and
                    conditions on which the Content accessed is provided, or the Sellers' compliance with an agreement that it may execute with Customer;
                    Customer and (ii) under no circumstances shall C2FO take title to, or in any manner obtain an interest in, or otherwise be deemed to be
                    within the chain of title of any Customer Content. Customer acknowledges that C2FO takes no responsibility for the terms and conditions
                    governing the provision of Customer Content. Customer shall address any issues arising from any transaction, agreement or arrangement
                    concluded between Customer and any Sellers using the Service exclusively with Sellers and shall not hold C2FO responsible or liable in any
                    way for the actions or omissions of any Sellers . Customer shall address any issues arising from any transaction, agreement or arrangement
                    for AR Extension concluded between the Customer and any Seller using the Service exclusively with the relevant Seller and shall not hold
                    C2FO responsible or liable in any way for the actions or omissions of any Sellers. Customer agrees that all C2FO Market Closings will be
                    held on a business day for the respective Seller.
                </p>
                <p>
                    3.2 <u>Availability of Service</u>. Customer understands, acknowledges and agrees that C2FO will use commercially reasonable efforts to make
                    the Service available 24 hours per day, 7 days a week, subject to downtime for scheduled and emergency maintenance and due to a Force
                    Majeure. Notwithstanding anything to the contrary stated herein, Customer acknowledges and agrees that the availability of the Service is
                    subject to the availability of connection services to and within the Internet and to other network functions within and around the Internet
                    and that the Internet, by its nature, is not fault-tolerant and C2FO shall not have any liability for any breach of any representation,
                    warranty or covenant of this Agreement that arises out of or relates to the unavailability of such connection services and other network
                    functions for whatever reason.
                </p>
                <p>
                    3.3 <u>Modification of Service</u>. Customer understands and agrees that C2FO may modify the Service, the name of the Service, or the manner
                    in which the Service are made available, and that those modifications may create differences in how Customer accesses the Service. Customer
                    further understands and agrees that, upon reasonable advance written notice to Customer, C2FO reserves the right to replace any of the
                    Service or any part thereof with services offering the same or more functionality than its predecessor.
                </p>
                <p>
                    <strong>4. PROPRIETARY RIGHTS</strong>
                </p>
                <p>
                    4.1 <u>Title to Technology</u>. All Intellectual Property Rights pertaining to C2FO, C2FO Information, Market Statistics and the Service, in
                    whole or in part, shall be, vest with and remain the exclusive property of C2FO and its third-party licensors. Customer shall not on its'
                    own behalf or on behalf of any third party violate C2FO's Intellectual Property Rights.
                </p>
                <p>
                    4.2 <u>Market Statistics</u>. Customer agrees that C2FO may use Transaction Information to create Market Statistics.
                </p>
                <p>
                    4.3 <u>Title to Content</u>. All title, right, and interest in and to Content submitted to C2FO shall remain the property of the Customer,
                    Seller, or other third-party owners, as applicable. If all or part of any Content becomes the subject of an actual or threatened lawsuit or
                    if C2FO believes such Content may violate a third party's Intellectual Property Rights or applicable law, C2FO will immediately be entitled
                    to remove such Content without incurring any liability to Customer.
                </p>
                <p>
                    4.4 <u>Suggestions</u>. C2FO shall have a royalty-free, worldwide, perpetual, irrevocable license to use or incorporate into the Service any
                    suggestions, ideas, enhancement requests, feedback, or recommendations provided by Customer and its Authorised Users relating to the
                    Service.
                </p>
                <p>
                    <strong>5. CONFIDENTIALITY</strong>
                </p>
                <p>
                    5.1 <u>Nondisclosure of Confidential Information</u>. Each Party shall retain the other Party's Confidential Information in confidence (need
                    to know basis) and shall not disclose such Confidential Information to any third party; provided, however, the Recipient may disclose
                    Confidential Information to its Affiliates, employees, officers, directors, auditors, agents, consultants, subcontractors, and advisors
                    (collectively, its "<strong>Representatives</strong>") who need to have access to such Confidential Information in connection with a Party's
                    exercise of its rights and performance of its obligations under this Agreement. As a condition to such disclosure, the Recipient shall
                    inform its Representatives of the confidential nature of the Confidential Information and shall be responsible for any disclosure or use of
                    the Confidential Information by any of its Representatives in a manner not Authorised by this Agreement. Each Party agrees: (i) to use the
                    Confidential Information only for the purposes of this Agreement and as expressly permitted by this Agreement; (ii) to treat this Agreement
                    as Confidential Information.
                    <strong> </strong>The Recipient shall notify the Disclosing Party in writing of any known unauthorised use, possession or disclosure of
                    Confidential Information of the Disclosing Party. The Disclosing Party shall have the sole right (but shall be under no obligation) to take
                    legal or other action against any third party with respect to any such unauthorised use, possession or disclosure of Confidential
                    Information of the Disclosing Party, and the Recipient shall cooperate with the Disclosing Party in such effort.
                </p>
                <p>
                    5.2 <u>Remedies</u>. The Parties agree that, notwithstanding any other section of this Agreement, in the event of a breach of Section 6.1,
                    the non-breaching Party shall be entitled to seek equitable relief to protect its interests, including but not limited to preliminary and
                    permanent injunctive relief, as well as money damages. Nothing stated in this Section 6.2 shall be construed to limit any other remedies
                    available to the Parties.
                </p>
                <p>
                    5.3 <u>Disclosures to Governmental Entities</u>. A Recipient may disclose such Confidential Information if disclosure is required pursuant
                    to a court order or instruction of any regulatory, supervisory, judicial or other governmental authority having jurisdiction over it ("
                    <strong>Compelled Disclosure</strong>"), provided that the Recipient will give the Disclosing Party (if allowed) prompt written notice. The
                    Recipient will reasonably cooperate with the Disclosing Party's reasonable efforts to challenge the Compelled Disclosure and will disclose
                    only such information as is required pursuant to the Compelled Disclosure. Notwithstanding anything herein to the contrary, a Compelled
                    Disclosure is not considered a breach of Section 6.1 above.
                </p>
                <p>
                    5.4 <u>Data Protection</u>. To the extent C2FO processes any Personal Data as a result of providing the Service, the Customer agrees that
                    C2FO does so as Data Processor and that the Customer is the Data Controller in relation to such Personal Data and in relation to such
                    Personal Data: (i) C2FO will process such Personal Data for the sole purpose of providing the Service in accordance with the terms of this
                    Agreement and any lawful written instructions reasonably given to C2FO by the Customer from time to time; and (ii) the Customer will have in
                    place appropriate technical and organizational security measures against unauthorised or unlawful processing of such Personal Data and
                    against accidental loss or destruction of, or damage to, such Personal Data. The Customer acknowledges and agrees that any Personal Data
                    processed as a result of providing the Service will be hosted or stored in the United States of America under the Privacy Shield framework.
                </p>
                <p>
                    <strong>6. TERM AND TERMINATION</strong>
                </p>
                <p>
                    6.1 <u>Term</u>. This Agreement shall become effective on the Effective Date and shall continue in force for until terminated by either
                    Party.
                </p>
                <p>
                    6.2 <u>Termination</u>. Either Party may terminate this Agreement if (a)(i) the other Party defaults (<strong>"Defaulting Party"</strong>)
                    under the terms, conditions and warranties of this Agreement; (ii) such default is not cured within twenty (20) Business Days after notice
                    of default is provided to Defaulting Party; or (b) the Defaulting Party terminates or suspends its business activities, becomes insolvent,
                    admits in writing its inability to pay its debts as they mature, makes an assignment for the benefit of creditors, or becomes subject to
                    direct control of a trustee, receiver or similar authority, or becomes subject to any bankruptcy or insolvency proceeding under applicable
                    law which is not rescinded within sixty (60) days of its commencement.
                </p>
                <p>
                    6.3 <u>Termination for Convenience</u>. Either Party may terminate this Agreement at any time for any reason at its sole discretion by
                    providing the other Party no less than thirty (30) days advance written notice thereof.
                </p>
                <p>
                    6.4 <u>Effect of Termination</u>. Upon termination of this Agreement consistent with the terms herein, C2FO may immediately discontinue
                    Customer's access and use of the Service. Customer shall promptly discontinue use of any Service and Confidential Information that Customer
                    has received from C2FO. C2FO may keep one (1) copy all Residual Data for archival, litigation, regulatory, financial, and audit tracking
                    purposes in accordance with standard data security, audit and accounting practices. C2FO is not required to affirmatively purge delete or
                    purge any records in backup or archival systems kept in the normal course of business.
                </p>
                <p>
                    6.5 <u>Survival</u>. Notwithstanding any termination of this Agreement, Section 6.1 (<strong>"Confidentiality"</strong>) shall survive for a
                    period of five (5) years, Section 9.5 (<strong>"Employee Solicitation"</strong>) shall survive for a period of one (1) year, while Sections
                    5 (<strong>"Proprietary Rights"</strong>), 7.3 (<strong>"Effect of Termination"</strong>); 8 (
                    <strong>"Warranty; Disclaimer; Limitation of Liability"</strong>), and 9.7 (<strong>"Governing Law"</strong>) shall survive termination of
                    this Agreement indefinitely. All other rights granted hereunder will cease upon termination.
                </p>
                <p>
                    <strong>7. WARRANTY; DISCLAIMER; LIMITATION OF LIABILITY</strong>
                </p>
                <p>
                    7.1 <u>Warranty.</u> C2FO warrants that: (i) it has validly entered into this Agreement and has the legal power to grant access to the
                    Service; (ii) it will provide the Services with reasonable skill and care; and (iii) the Service will perform materially in accordance with
                    the technical Service description available at www.c2fo.com.
                </p>
                <p>
                    7.2 <u>Disclaimer</u>. To the maximum extent allowed by law and except as unambiguously and expressly set forth in this Agreement, the
                    Services are provided "as is" and C2FO specifically excludes and disclaims all implied warranties, conditions and representations (including
                    relation to quality, skill and care and fitness for a particular purpose) connected with, related to or arising out of this Agreement. C2FO
                    expressly excludes and disclaims any representation, condition or warranty that access or use of the Service will be error-free, secure or
                    uninterrupted, or that information or content will be accurate or timely.
                </p>
                <p>
                    7.3 <u>Limitation of Liability</u>. To the maximum extent allowed by law, in no event will C2FO (and any C2FO subcontractor) be liable for
                    any loss of profits or revenue, loss of business, loss of anticipated savings, loss of use, business interruption, loss of data or costs of
                    procurement of substitute goods, technologies or services, cost of cover or punitive or exemplary, or indirect, special, incidental or
                    consequential damages of any kind in connection with or arising out of the furnishing, performance or use of the Service, whether alleged as
                    a breach of contract or tortious conduct, including negligence, even if Customer has been advised of the possibility of such damages. In
                    addition, C2FO (and any C2FO subcontractor) will not be liable for any damages caused by delay in delivery or furnishing the Service. C2FO's
                    aggregate liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise,
                    arising in connection with the performance or contemplated performance of this Agreement, will not, in any event, exceed the Fees actually
                    paid by Customer to C2FO pursuant to this Agreement during the twelve (12) months prior to the occurrence of the breach or damage.
                </p>
                <p>
                    7.4 <u>Exclusion</u>. Nothing in this Agreement excludes the liability of C2FO for death or personal injury caused by C2FO's negligence or
                    for fraud or fraudulent misrepresentation.
                </p>
                <p>
                    <strong>8. GENERAL</strong>
                </p>
                <p>
                    8.1 <u>Notices</u>. Any notice required or permitted under the terms of this Agreement shall be delivered in person, by FAX, overnight
                    courier service, or mailed by first class, registered, or certified mail, postage prepaid, to Attention: Legal Notices, C2FO Limited, 9th
                    Floor, 107 Cheapside, London, EC2V 6DN; with a copy to: Attention: Legal Notices, C2FO Ltd, 6th Floor, 105 Southside, Victoria Street,
                    London SW1E 6D if to C2FO; and Attention the name of the individual you entered during the registration process, at the address you entered
                    during the registration process, if to you. All such notices shall be deemed to have been given upon receipt.
                </p>
                <p>
                    8.2 <u>Mutual Representations</u>. Each Party represents and warrants that: (i) it is duly organized, validly existing and in good standing
                    under the laws of its state of domicile; (ii) it has the power and authority to execute, deliver and perform under this Agreement; and (iii)
                    this Agreement constitutes a valid and binding obligation enforceable in accordance with its terms.
                </p>
                <p>
                    8.3 <u>C2FO Representations</u>. The Service will (i) perform materially in accordance with the Functional Description; (ii) provide
                    accurate Award Files which are calculated correctly (to the extent that the underlying data provided by the Customer and Seller(s) is
                    correct); (iii) accurately present Customer Content as provided to C2FO; and (iv) use current commercially-available virus scanning
                    technologies with current virus signatures to protect the Service from Malicious Code.
                </p>
                <p>
                    8.4 <u>Compliance</u>. Customer represents that: (i) it will provide to C2FO any information in its possession related to Authorized Users
                    who may be a Sanctioned Person (<strong>"Flagged Person"</strong>); (ii) if C2FO determines in its commercially reasonable discretion that
                    any Authorized User is the subject of any Sanctions or other related restrictions, C2FO will deactivate the Flagged Person as an Authorized
                    User; (iii) Customer shall comply with all relevant laws and regulations, anti-bribery/corruption, anti-money laundering and export control
                    laws, and tax information reporting requirements applicable to this Agreement; and (iv) if notified by Customer's financial institution that
                    an Authorized Supplier has been flagged as subject to Sanctions (<strong>"Flagged Supplier"</strong>), Customer will notify C2FO, who may
                    deactivate the Flagged Supplier access, and no further invoices for the Flagged Supplier will be submitted to the Service.
                </p>
                <p>
                    8.5 <u>Employee Solicitation</u>. Both Parties acknowledge that each other's business is dependent upon being able to adequately staff
                    projects with qualified persons and adequately utilize its employees and independent contractors. Neither Party shall, directly or
                    indirectly, for itself, or on behalf of any other person, firm, corporation or other entity, whether as principal, agent, employee,
                    stockholder, partner, member, officer, director, sole proprietor, or otherwise, solicit, participate in or promote the solicitation of the
                    other Party's employees or independent contractors to leave the employ or service of the other Party, during the period such employee or
                    independent contractor is working with the other Party and for one (1) year immediately following the period for which such employee or
                    independent contractor last performed services for the other Party.
                </p>
                <p>
                    8.6 <u>Assignment.</u> Neither Party shall assign or transfer this Agreement, nor any interest in this Agreement, except with written
                    consent of the other Party, which consent shall not be Customer unreasonably withheld. Notwithstanding the foregoing, the Parties agree that
                    assignment or other transfer, in whole or in part, whether voluntarily or by operation of lawCustomer in the context of (i) a tax-free
                    reorganization or the sale of a Party's stock or assets or (ii) an assignment to a wholly-owned Affiliate shall not constitute an
                    "assignment" for purposes of this prohibition. Subject to the foregoing, this Agreement will be binding upon and will inure to the benefit
                    of the Parties and their respective successors and assigns. Notwithstanding anything to the contrary, C2FO shall have the right to
                    subcontract any of its obligations hereunder to third parties, provided that C2FO shall remain responsible for the performance of any such
                    obligations and will bind any such subcontractors to confidentiality provisions no less stringent than contained herein.
                </p>
                <p>
                    8.7 <u>Governing Law</u>. This Agreement and any disputes or claims arising out of or in connection with it or its subject matter or
                    formation (including non-contractual disputes or claims) are governed by and construed in accordance with the laws of England and Wales. The
                    parties irrevocably agree that the courts of England and Wales have exclusive jurisdiction to settle any dispute or claim arising out of or
                    in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims).
                </p>
                <p>
                    8.8 <u>Independent Contractors</u>. The relationship of C2FO and Customer established by this Agreement is that of independent contractors,
                    and nothing contained in this Agreement shall be construed or implied to give either Party the power to direct or control the day-to-day
                    activities of the other or constitute the Parties as partners, joint venturers, co-owners or otherwise as participants in a joint or common
                    undertaking.
                </p>
                <p>
                    8.9 <u>Publicity.</u> Upon execution of this Agreement, C2FO shall have permission to (i) represent that Customer is a customer of the
                    Service in sales presentations; and (ii) allowing C2FO to reference the Customer on its account customer lists in print and on its website.
                </p>
                <p>
                    8.10 <u>VAT Adjustments</u>. For any invoices which are subject to VAT/GST regulation, the Award File may require the Customer, where
                    appropriate, to post an adjustment to VAT/GST records. Subject to relevant local VAT/GST regulations, the Customer may treat the Award File
                    as an appropriate document to adjust VAT/GST records. Please note that the responsibility of ensuring an accurate VAT/GST adjustment is
                    posted rests solely with the Customer and not with C2FO. Where VAT/GST has not been recorded in respect of an invoice which has been
                    uploaded into the Service (but has been nonetheless incurred and reclaimed), it is the sole responsibility of Customer to ensure that normal
                    processes to post any manual VAT/GST adjustments which may be required are followed and the correct VAT/GST amount is repaid to the
                    appropriate tax authorities. By accepting this clause, the Customer acknowledges that both the Customer and the Seller have authorised C2FO
                    to issue the Award File on their behalf whilst clearly showing the Customer and Seller names and other relevant details.
                </p>
                <p>
                    8.11 <u>Miscellaneous</u>. In the event that any provision of this Agreement conflicts with governing law or if any provision is held to be
                    null, void or otherwise ineffective or invalid by a court of competent jurisdiction: (i) such provision shall be deemed to be restated to
                    reflect as nearly as possible the original intentions of the Parties in accordance with applicable law; and (ii) the remaining terms,
                    provisions, covenants and restrictions of this Agreement shall remain in full force and effect. The failure of either Party to enforce at
                    any time any of the provisions of this Agreement shall not be deemed to be a waiver of the right of either Party thereafter to enforce any
                    such provisions. No waiver, amendment or variation to this Agreement shall be valid unless in writing and signed by both Parties. Except for
                    the obligation to make payments, nonperformance of either Party shall be excused to the extent that performance is affected by Force
                    Majeure. This Agreement may be executed in counterparts, each of which so executed will be deemed to be an original and such counterparts
                    together will constitute one and the same Agreement. Section and Attachment headings are for ease of reference only and do not form part of
                    this Agreement. This is an integrated Agreement and all exhibits, schedules and attachments hereto and incorporated herein constitute the
                    entire, final, complete and exclusive agreement between the Parties and supersede all previous agreements, intentions, or representations,
                    oral or written, relating to this Agreement. This Agreement may not be modified or amended except in a writing signed by a duly authorized
                    representative of each Party. Both Parties acknowledge having read the terms and conditions set forth in this Agreement and all attachments
                    hereto, understand all terms and conditions, and agree to be bound thereby. No employee, agent, representative, or Affiliate of C2FO has
                    authority to bind C2FO to any oral representations or warranty concerning the Service. Any written representation or warranty not expressly
                    contained in this Agreement (including any Attachments) will not be enforceable.
                </p>
            </div>
            {showAgreementButtons && (
                <Box className={classes.agreementButtons}>
                    <Button variant="contained" color="primary" className={classes.acceptButton} onClick={handleAcceptTerms}>
                        I AGREE
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleDeclineTerms}>
                        DECLINE
                    </Button>
                </Box>
            )}
        </Box>
    );
};

const useStyles = makeStyles<Theme>(theme => ({
    termsPage: {
        height: '100%',
        padding: theme.spacing(2, 4, 4, 4),
    },
    termsContainer: {
        background: 'white',
        color: '#333',
        height: '80vh',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px',
        overflowY: 'scroll',
        border: '1px solid #333',
        borderRadius: '5px',
        padding: theme.spacing(0, 4),
        '&> p': {
            textAlign: 'justify',
        },
        '&> h2': {
            margin: theme.spacing(4, 0),
            textAlign: 'center',
        },
    },
    lastModifiedDate: {
        paddingBottom: theme.spacing(2),
    },
    agreementButtons: {
        height: '15vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    acceptButton: {
        marginRight: theme.spacing(4),
    },
}));
