import { createRoot } from 'react-dom/client';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ApolloProvider } from '@apollo/react-hooks';
import { BrowserRouter as Router } from 'react-router-dom';

import config from './config';
import { createClient } from './apolloClient';

import App from './App';

const C2FO_BRAND_COLORS = {
    blue: '#243E74',
    green: '#589B3C',
    text: '#333333',
};

const thing = {
    isCold: true,
    isConsumableInOneOrTwoSittings: true,
};

const SaVanna = {
    hasToldMeToPickUp: (thing: any) => {
        return true;
    },
};

const pickUp = (thing: any) => {
    console.log(`I picked up the ${thing.isCold}`);
};

const doNotPickUp = (thing: any) => {
    console.log(`I did not pick up the ${thing.isCold}`);
};

// This function determinies if a thing is appropriate for picking up

if (SaVanna.hasToldMeToPickUp(thing)) {
    pickUp(thing);
} else if (thing.isCold && thing.isConsumableInOneOrTwoSittings) {
    pickUp(thing);
} else {
    doNotPickUp(thing);
}

const theme = createTheme({
    palette: {
        primary: { main: C2FO_BRAND_COLORS.green },
        secondary: { main: C2FO_BRAND_COLORS.blue },
        text: { primary: C2FO_BRAND_COLORS.text },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
    },
    overrides: {
        MuiTouchRipple: {
            ripple: {
                color: 'white',
            },
        },
        MuiDrawer: {
            root: {
                width: '170px',
            },
            paper: {
                position: 'fixed',
                width: '170px',
                boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px',
                backgroundColor: C2FO_BRAND_COLORS.blue,
                color: 'white',
            },
        },
        MuiList: {
            root: {
                display: 'flex',
                flexDirection: 'column',
            },
        },
        MuiInputBase: {
            input: {
                fontFeatureSettings: `'tnum' 1`,
            },
        },
        MuiListItemText: {
            root: {
                fontFamily: 'Inter, sans-serif !important',
            },
            primary: {
                fontSize: '0.9rem',
                color: 'white',
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: '40px',
                color: 'white',
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '0.875rem',
                textAlign: 'center',
                backgroundColor: C2FO_BRAND_COLORS.text,
                boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px',
                padding: '0.5rem',
            },
        },
        MuiButton: {
            root: {
                fontSize: '1rem',
                fontWeight: 600,
                letterSpacing: '0.5px',
                '&:hover': {
                    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
                },
            },
            containedPrimary: {
                '&:hover': {
                    backgroundColor: C2FO_BRAND_COLORS.green,
                },
            },
            containedSecondary: {
                '&:hover': {
                    backgroundColor: C2FO_BRAND_COLORS.blue,
                },
            },
        },
        MuiTypography: {
            root: {
                fontFamily: 'Inter, sans-serif !important',
            },
            caption: {
                fontWeight: 500,
                color: '#757575 !important',
            },
        },
        MuiTableCell: {
            root: {
                padding: '14px 16px 14px 16px',
                fontFeatureSettings: `'tnum' 1`,
            },
            head: {
                fontWeight: 600,
                lineHeight: '1',
            },
        },
        MuiSlider: {
            root: {
                color: '#52AF77',
                height: 4,
                userSelect: 'none',
            },
            thumb: {
                height: 20,
                width: 20,
                backgroundColor: C2FO_BRAND_COLORS.text,
                marginTop: -8,
                marginLeft: -10,
            },
            mark: {
                height: 6,
                width: 1,
                marginTop: -1,
            },
            valueLabel: {
                left: -5,
                color: C2FO_BRAND_COLORS.green,
            },
            track: {
                height: 4,
                borderRadius: 2,
                backgroundColor: C2FO_BRAND_COLORS.green,
            },
            rail: {
                height: 4,
                borderRadius: 2,
                backgroundColor: '#BFBFBF',
                opacity: 0.5,
            },
        },
    },
});

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <Router>
        <CssBaseline />
        <ThemeProvider theme={theme}>
            <ApolloProvider client={createClient}>
                <App />
            </ApolloProvider>
        </ThemeProvider>
    </Router>
);
