import React, { useState, useEffect } from 'react';
import Numeral from 'numeral';
import { orderBy } from 'lodash';
import { Spring, animated as a, to } from '@react-spring/web';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/AddRounded';
import RemoveIcon from '@material-ui/icons/RemoveRounded';
import ScheduleIcon from '@material-ui/icons/ScheduleRounded';

import { MouseOverPopover } from '../../components/';
import borealisLogo from '../../assets/borealis-logo.svg';
import { getUserType } from '../../services/AuthService';
import { useInterval } from '../../services/CommonUtils';

import { EnhancedTableToolbar } from './EnhancedTableToolbar/EnhancedTableToolbar';
import { EnhancedTableHead } from './EnhancedTableHead/EnhancedTableHead';

import { Spring as ISpring } from './ActiveInvoices.schema';
import { Invoice } from '../../schemas/invoice.schema';
import { MarketSettings } from '../../schemas/market.schema';
import CircularProgress from '@material-ui/core/CircularProgress';

// GraphQL calls
import {
    useSubmitOffer as updateMarketOfferMutation,
    useCancelOffer as cancelOfferMutation,
    useGetCustomerInfoQuery as getCustomerInfo,
} from '../../services/graphql';
import { useLocation, useParams } from 'react-router';

const DEFAULT_ROWS_PER_PAGE = 10;

export const EnhancedTable: React.FC = props => {
    const classes = useStyles();
    const [marketCloseTimestamp, setMarketCloseTimestamp] = useState<Date>();
    const [marketCloseCountdown, setMarketCloseCountdown] = useState<string>();
    const [apr, setApr] = useState<number>(0);
    const [daysExtended, setDaysExtended] = useState<number>(7);
    const [minDays] = useState<number>(7);
    const [maxDays, setMaxDays] = useState<number>(7);
    const [order, setOrder] = useState<'desc' | 'asc' | boolean>('desc');
    const [orderCol, setOrderCol] = useState<string>('amount_grossvat');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
    const [selectedAmount, setSelectedAmount] = useState<number>(0);
    const [totalEligibleAmount, setTotalEligibleAmount] = useState<number>(0);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [includeAllInvoices, setIncludeAllInvoices] = useState<boolean>(false);
    const [flipped, setFlipped] = useState<boolean>(false);
    const [submitButtonPressed, setSubmitButtonPressed] = useState<boolean>(false);
    const [offerConfirmed, setOfferConfirmed] = useState<boolean>(false);
    const [includeAllDialogOpen, setIncludeAllDialogOpen] = useState<boolean>(false);
    const [userType] = useState<string | null>(getUserType());
    const [minApr, setMinApr] = useState<number>(0);
    const [lockApr, setLockApr] = useState<boolean>(true);
    const [marks, setMarks] = useState<{ value: number }[]>();
    const [showCountdownTooltip, setShowCountdownTooltip] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const location = useLocation();
    const params = useParams();

    const companyId = params.companyId;
    const pathname = location.pathname;

    const submitOffer = updateMarketOfferMutation();
    const cancelOffer = cancelOfferMutation();

    const data = getCustomerInfo({
        company_id: companyId,
    });

    useEffect(() => {
        setIsLoading(true);
        if (data) {
            const marketSettings: MarketSettings = data.customer;
            setupMarket(marketSettings);
            console.log('data.activeInvoices', data.activeInvoices);
            populateInvoices(data.activeInvoices, marketSettings.include_all_invoices);
            setIsLoading(false);
        }
    }, [companyId, pathname, data]);

    useInterval(() => {
        const marketCloseCountdown = calculateCountdown(marketCloseTimestamp);
        setMarketCloseCountdown(marketCloseCountdown);
    }, 60000);

    const setupMarket = async (marketSettings: MarketSettings) => {
        setMaxDays(marketSettings.max_ext);
        setMinApr(marketSettings.min_apr / 100);
        setApr((marketSettings.apr_offer || marketSettings.min_apr) / 100);
        setLockApr(marketSettings.lock_apr);
        setIncludeAllInvoices(marketSettings.include_all_invoices);
        setDaysExtended(marketSettings.days_extended_offer || minDays);
        setMarketCloseTimestamp(marketSettings.next_clear_time);
        setMarketCloseCountdown(calculateCountdown(marketSettings.next_clear_time));
    };

    const populateInvoices = async (invoices: Invoice[], include_all_invoices: boolean) => {
        invoices = invoices.map(
            e =>
                ({
                    ...e,
                    payment_due_date: new Date(e.payment_due_date),
                }) as Invoice
        );
        invoices.sort((a, b) => b.amount_grossvat - a.amount_grossvat);

        const marks = [{ value: 0 }];
        const totalEligibleAmount = invoices.reduce((total, row) => {
            marks.push({ value: total + row.amount_grossvat });
            return total + row.amount_grossvat;
        }, 0);
        let selectedAmount = 0;
        let offerConfirmed = include_all_invoices;
        let selected: string[] | null = [];
        if (invoices.filter(i => i.included).length) {
            selectedAmount = invoices.filter(i => i.included).reduce((total, row) => total + row.amount_grossvat, 0);
            offerConfirmed = true;
            selected = invoices.filter(i => i.included).map(i => i.invoice_id);
        }

        setInvoices(invoices);
        setMarks(marks);
        setTotalEligibleAmount(totalEligibleAmount);
        setOfferConfirmed(offerConfirmed);
        setSelected(selected);
        setFlipped(offerConfirmed);
        setSelectedAmount(selectedAmount);
    };

    const calculateCountdown = endDate => {
        if (!endDate) return;
        let diff = (Date.parse(String(endDate)) - Date.parse(String(new Date()))) / 1000;

        const timeLeft = {
            days: 0,
            hours: 0,
            min: 0,
        };

        if (diff >= 86400) {
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) {
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        return `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.min}m`;
    };

    const handleRequestSort = (event: React.MouseEvent<HTMLElement>, property: string) => {
        const isDesc = orderCol === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderCol(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event && event.target.checked) {
            const newSelected: string[] = invoices.map((n: any) => n.invoice_id);
            setSelectedAmount(totalEligibleAmount);
            setSelected(newSelected);
        } else handleChangeAmountSlider(null, 0);
    };

    const handleSelectInvoiceClick = (event: React.MouseEvent, invoiceId: string) => {
        const selectedIndex = selected.indexOf(invoiceId);
        let newSelected: string[] = [];

        if (selectedIndex === -1) newSelected = newSelected.concat(selected, [invoiceId as any]);
        else if (selectedIndex === 0) newSelected = newSelected.concat(selected.slice(1));
        else if (selectedIndex === selected.length - 1) newSelected = newSelected.concat(selected.slice(0, -1));
        else if (selectedIndex > 0) newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        let newSelectedAmount;
        if (invoices) {
            const selectedInvoiceAmount = invoices.find(row => row.invoice_id === invoiceId.toString())?.amount_grossvat ?? 0;
            if (selectedIndex === -1) {
                newSelectedAmount = selectedAmount + selectedInvoiceAmount;
            } else {
                newSelectedAmount = selectedAmount - selectedInvoiceAmount;
            }
        }
        setSelected(newSelected);
        setSelectedAmount(newSelectedAmount);
        setIncludeAllInvoices(false);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleChangeAmountSlider = (event, value: number | number[]) => {
        if (!value && includeAllInvoices) {
            setIncludeAllInvoices(false);
            setSelected([]);
            setSelectedAmount(0);
            return;
        } else if (value === selectedAmount) return;

        const markIndex = marks?.findIndex(mark => mark.value === value);
        const selectedInvoices = invoices.sort((a, b) => b.amount_grossvat - a.amount_grossvat).slice(0, markIndex);
        const selectedInvoiceIds = selectedInvoices.map(invoice => invoice.invoice_id);
        setSelectedAmount(value as number | 0);
        setSelected(selectedInvoiceIds);
        setIncludeAllInvoices(false);
    };

    const formatAmountLabel = (value: number) => `€${Numeral(value).format('0.[00]a')}`;

    const handleIncludeAllInvoicesSwitch = (event: React.ChangeEvent<any>, value: boolean) => {
        if (value) setIncludeAllDialogOpen(true);
        else {
            setIncludeAllDialogOpen(false);
            setIncludeAllInvoices(false);
        }
    };

    const handleChangeDaySlider = (event: React.ChangeEvent<any>, value: number | number[]) => setDaysExtended(value as number);

    const handleIncreaseApr = (event: React.MouseEvent) => setApr(Math.min(apr + 0.0025, 100));
    const handleDecreaseApr = (event: React.MouseEvent) => setApr(Math.max(apr - 0.0025, 0));

    const handleConfirmOffer = () => {
        setOfferConfirmed(true);
        setShowCountdownTooltip(true);
        setTimeout(() => setShowCountdownTooltip(false), 5000);
        submitOffer({
            variables: {
                company_id: companyId,
                invoice_ids: selected,
                include_all_invoices: includeAllInvoices,
                days_extended_offer: daysExtended,
                apr_offer: +parseFloat(String(apr * 100)).toFixed(2),
            },
        });
    };

    const handleCancelOffer = () => {
        setOfferConfirmed(false);
        setFlipped(false);
        setApr(lockApr ? minApr : apr);
        cancelOffer({
            variables: {
                company_id: companyId,
            },
        });
    };

    const handleIncludeAllDialogCancel = () => setIncludeAllDialogOpen(false);

    const handleIncludeAllDialogConfirm = () => {
        const newSelected: string[] = invoices.map(n => n.invoice_id);
        setIncludeAllDialogOpen(false);
        setIncludeAllInvoices(true);
        setSelectedAmount(totalEligibleAmount);
        setSelected(newSelected);
    };

    let rowsCurrentPage;
    if (rowsCurrentPage) rowsCurrentPage = Math.min(rowsPerPage, invoices.length - page * rowsPerPage);
    const emptyRows = rowsCurrentPage < DEFAULT_ROWS_PER_PAGE ? DEFAULT_ROWS_PER_PAGE - rowsCurrentPage : 0;

    let totalPremiumAmount;
    if (invoices && selected) {
        totalPremiumAmount = invoices
            .filter(invoice => selected.includes(invoice.invoice_id))
            .reduce((total, row) => {
                const premiumAmount = parseFloat(String(((row.amount_grossvat * apr) / 360) * daysExtended)).toFixed(2);
                return total + parseFloat(premiumAmount);
            }, 0);
    }

    const springFrom: ISpring = flipped
        ? { opacity: 1, transform: 'perspective(600px) rotateX(180deg)' }
        : { opacity: 0, transform: 'perspective(600px) rotateX(0deg)' };
    const springTo: ISpring = flipped
        ? { opacity: 0, transform: 'perspective(600px) rotateX(0deg)' }
        : { opacity: 1, transform: 'perspective(600px) rotateX(180deg)' };

    const includeAllAsteriskTooltip = (
        <Tooltip
            classes={{ tooltip: classes.includeAllInvoicesSwitchTooltip }}
            placement="top"
            title="Because Include All Invoices is selected, this number may increase when more invoices are uploaded in the future.">
            <span>*</span>
        </Tooltip>
    );

    return (
        <div className={classes.activeInvoicesRoot}>
            <Paper className={classes.paper}>
                <div className={classes.marketHeader}>
                    <img src={borealisLogo} alt="Vendor logo" className={classes.vendorLogo} />
                    {marketCloseCountdown ? (
                        <Tooltip
                            classes={{ tooltip: classes.marketCountdownTooltip }}
                            placement="top"
                            open={showCountdownTooltip}
                            onOpen={() => setShowCountdownTooltip(true)}
                            onClose={() => setShowCountdownTooltip(false)}
                            title="Offers will lock in at this time, to be processed by your vendor.">
                            <Box className={classes.marketCloseCountdownContainer}>
                                <Box className={classes.marketCloseCountdown}>
                                    <ScheduleIcon />
                                    <Typography>{marketCloseCountdown}</Typography>
                                </Box>
                                <Typography className={classes.marketCountdownTitle}>Market Ends</Typography>
                            </Box>
                        </Tooltip>
                    ) : (
                        <Box className={classes.marketCloseCountdownContainer}>
                            <Typography className={classes.marketCountdownTitle}>Market Closed</Typography>
                        </Box>
                    )}
                </div>
                <Spring config={{ mass: 7.5, tension: 800, friction: 90 }} from={submitButtonPressed ? springFrom : springTo} to={springTo}>
                    {({ transform, opacity }) => (
                        <Box className={classes.offerDetailsContainer}>
                            <a.div className={classes.confirmOfferContainer} style={{ zIndex: flipped ? 2 : 0, transform }}>
                                <a.div className={classes.confirmResultContainer} style={{ opacity: to([opacity], o => `${1 - o}`) }}>
                                    {offerConfirmed && (
                                        <Typography className={classes.offerLivePulseContainer}>
                                            <span className={classes.offerLivePulseIcon} />
                                            OFFER LIVE
                                        </Typography>
                                    )}

                                    <Box textAlign="center">
                                        <Typography variant="caption">Invoices Offered</Typography>
                                        <Typography>
                                            €{Numeral(selectedAmount).format('#,##0.00')}
                                            {includeAllInvoices && includeAllAsteriskTooltip}
                                        </Typography>
                                    </Box>
                                    <Box textAlign="center">
                                        <Typography variant="caption">Days Extended</Typography>
                                        <Typography>{daysExtended}</Typography>
                                    </Box>
                                    <Box textAlign="center">
                                        <Typography variant="caption">APR</Typography>
                                        <Typography>{(apr * 100).toFixed(2)}%</Typography>
                                    </Box>
                                    <Box textAlign="center">
                                        <Typography variant="caption">Total Premium</Typography>
                                        <Typography>
                                            €{Numeral(totalPremiumAmount).format('#,##0.00')}
                                            {includeAllInvoices && includeAllAsteriskTooltip}
                                        </Typography>
                                    </Box>
                                </a.div>
                            </a.div>
                            <a.div
                                className={classes.offerConfigContainer}
                                style={{ zIndex: !flipped ? 2 : 0, transform: to(transform, t => `${t} rotateX(180deg)`) }}>
                                <a.div style={{ opacity }}>
                                    <Box display="flex">
                                        <Typography className={classes.headerTextBox}>APR</Typography>

                                        <div className={classes.aprInputContainer}>
                                            <Input className={classes.aprInput} value={`${(apr * 100).toFixed(2)} %`} />
                                            <div className={classes.aprInputButtonsContainer}>
                                                <Button
                                                    size="small"
                                                    disabled={lockApr || userType === 'Vendor'}
                                                    onClick={handleIncreaseApr}
                                                    className={classes.aprInputButton}>
                                                    <AddIcon />
                                                </Button>
                                                <Button
                                                    size="small"
                                                    disabled={lockApr || userType === 'Vendor'}
                                                    onClick={handleDecreaseApr}
                                                    className={classes.aprInputButton}>
                                                    <RemoveIcon />
                                                </Button>
                                            </div>
                                        </div>

                                        <Typography className={classes.headerTextBox}>Days Extended</Typography>

                                        <span className={classes.daySliderInnerContainer}>
                                            <span className={classes.daysExtendedMinMaxContainer}>
                                                <Typography className={classes.daysExtendedMinMaxTitle}>MIN</Typography>
                                                <Typography className={classes.daysExtendedMinMaxText}>{minDays}</Typography>
                                            </span>
                                            <Slider
                                                className={classes.daySlider}
                                                value={invoices && !invoices.length && !includeAllInvoices ? 0 : daysExtended}
                                                defaultValue={0}
                                                valueLabelDisplay="auto"
                                                onChange={handleChangeDaySlider}
                                                disabled={(invoices && !invoices.length && !includeAllInvoices) || userType === 'Vendor'}
                                                step={1}
                                                min={minDays}
                                                max={maxDays}
                                            />
                                            <span className={classes.daysExtendedMinMaxContainer}>
                                                <Typography className={classes.daysExtendedMinMaxTitle}>MAX</Typography>
                                                <Typography className={classes.daysExtendedMinMaxText}>{maxDays}</Typography>
                                            </span>
                                        </span>
                                    </Box>
                                    <Divider />
                                    <Box display="flex">
                                        <Typography className={classes.headerTextBox}>Invoice Amount</Typography>

                                        <Slider
                                            className={classes.amountSlider}
                                            value={selectedAmount}
                                            disabled={includeAllInvoices || (invoices && !invoices.length && !includeAllInvoices) || userType === 'Vendor'}
                                            defaultValue={0}
                                            valueLabelDisplay="auto"
                                            onChange={handleChangeAmountSlider}
                                            step={null}
                                            marks={marks}
                                            min={0}
                                            max={totalEligibleAmount}
                                            valueLabelFormat={formatAmountLabel}
                                        />

                                        <Tooltip
                                            classes={{ tooltip: classes.includeAllInvoicesSwitchTooltip }}
                                            placement="top"
                                            title="Extends all invoices, and will automatically include any invoices that may be uploaded in the future.">
                                            <FormControlLabel
                                                className={classes.includeAllInvoicesSwitchLabel}
                                                onChange={handleIncludeAllInvoicesSwitch}
                                                control={<Switch disabled={userType === 'Vendor'} checked={includeAllInvoices} color="primary" />}
                                                label="Include All Invoices"
                                                labelPlacement="top"
                                            />
                                        </Tooltip>
                                        <Dialog open={includeAllDialogOpen} onClose={handleIncludeAllDialogCancel}>
                                            <DialogTitle>Include All Invoices?</DialogTitle>
                                            <DialogContent>
                                                <DialogContentText>
                                                    Clicking <strong>CONFIRM</strong> below will activate an offer on all eligible invoices.
                                                    <br /> <br />
                                                    In addition, once submitted, the offer will remain active and continue to be applied to any invoices which
                                                    are subsequently loaded to the platform, until turned off by the user.
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleIncludeAllDialogCancel}>Cancel</Button>
                                                <Button onClick={handleIncludeAllDialogConfirm} color="primary" autoFocus>
                                                    Confirm
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Box>
                                </a.div>
                            </a.div>
                        </Box>
                    )}
                </Spring>

                {!isLoading && selected && (
                    <EnhancedTableToolbar
                        userType={userType}
                        numSelected={selected.length}
                        includeAllInvoices={includeAllInvoices}
                        selectedAmount={selectedAmount}
                        flipped={flipped}
                        flipHeader={() => {
                            setFlipped(!flipped);
                            setSubmitButtonPressed(true);
                        }}
                        handleConfirm={handleConfirmOffer}
                        handleModify={handleCancelOffer}
                        offerConfirmed={offerConfirmed}
                        totalEligibleAmount={totalEligibleAmount}
                        totalPremiumAmount={totalPremiumAmount}
                    />
                )}
                {!isLoading ? (
                    selected && (
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <EnhancedTableHead
                                    userType={userType}
                                    numSelected={selected.length}
                                    order={order}
                                    orderCol={orderCol}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={invoices.length}
                                    offerConfirmed={offerConfirmed}
                                    flipped={flipped}
                                />
                                {invoices.length > 0 ? (
                                    <TableBody>
                                        {orderBy(invoices, [orderCol], [order])
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.invoice_id);
                                                const selectedCellStyle = {
                                                    width: selected.length ? '150px' : '0',
                                                    padding: selected.length ? '14px 16px 14px 16px' : '0',
                                                };
                                                const newDueDate = new Date(
                                                    row.payment_due_date.getFullYear(),
                                                    row.payment_due_date.getMonth(),
                                                    row.payment_due_date.getUTCDate() + daysExtended
                                                );
                                                const premiumAmount = ((row.amount_grossvat * apr) / 360) * daysExtended;
                                                const newGrossAmount = row.amount_grossvat + premiumAmount;

                                                return (
                                                    <TableRow
                                                        hover
                                                        tabIndex={-1}
                                                        key={row.invoice_id}
                                                        selected={isItemSelected}
                                                        onClick={event =>
                                                            !offerConfirmed &&
                                                            !flipped &&
                                                            userType === 'Customer' &&
                                                            handleSelectInvoiceClick(event, row.invoice_id)
                                                        }
                                                        role="checkbox">
                                                        <TableCell padding="checkbox">
                                                            <Checkbox checked={isItemSelected} disabled={offerConfirmed || flipped || userType === 'Vendor'} />
                                                        </TableCell>
                                                        <TableCell component="th" id={`enhanced-table-checkbox-${index}`} scope="row" padding="none">
                                                            {row.invoice_id} <MouseOverPopover content={row.description} />
                                                        </TableCell>
                                                        <TableCell align="right">€{Numeral(row.amount).format('#,##0.00')}</TableCell>
                                                        <TableCell align="right">€{Numeral(row.vat_amount).format('#,##0.00')}</TableCell>
                                                        <TableCell align="right">€{Numeral(row.amount_grossvat).format('#,##0.00')}</TableCell>
                                                        <TableCell align="right">{row.payment_due_date.toISOString().slice(0, 10)}</TableCell>
                                                        <TableCell
                                                            style={{
                                                                ...selectedCellStyle,
                                                                borderLeft: selected.length && '1px solid rgba(0, 0, 0, 0.12)',
                                                                width: selected.length ? '115px' : '0',
                                                            }}
                                                            align="right">
                                                            {isItemSelected ? `€${Numeral(premiumAmount).format('#,##0.00')}` : ''}
                                                        </TableCell>
                                                        <TableCell style={selectedCellStyle} align="right">
                                                            {isItemSelected ? `€${Numeral(newGrossAmount).format('#,##0.00')}` : ''}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                ...selectedCellStyle,
                                                                width: selected.length ? '125px' : '0',
                                                            }}
                                                            align="right">
                                                            {isItemSelected ? newDueDate.toISOString().slice(0, 10) : ''}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 49 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                ) : (
                                    <TableBody className={classes.emptyTableBody}>
                                        <TableRow style={{ height: 490 }}>
                                            <TableCell className={classes.emptyTableInfoCell} colSpan={6}>
                                                <Typography className={classes.emptyTableInfo}>
                                                    No eligible invoices are currently available for extension.
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </div>
                    )
                ) : (
                    <Box className={classes.loader}>
                        <CircularProgress disableShrink size={80} thickness={6} />
                    </Box>
                )}
                {invoices && (
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={invoices.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, newPage) => setPage(newPage)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </Paper>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    activeInvoicesRoot: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        minHeight: 'calc(100vh - 34px)',
    },
    paper: {
        width: '98%',
        maxWidth: '1200px',
        margin: '2em 0 4em 0',
        alignSelf: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px',
        position: 'relative' as 'relative',
    },
    marketHeader: {
        backgroundColor: '#243E74',
        borderRadius: '4px 4px 0 0',
        overflow: 'hidden',
        width: '100%',
        height: '5.75rem',
        display: 'flex',
    },
    vendorLogo: {
        position: 'absolute' as 'absolute',
        width: '225px',
        background: 'white',
        padding: '0.625rem',
        borderRadius: '5px',
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)',
        margin: '-0.5rem',
    },
    marketCloseCountdownContainer: {
        display: 'flex',
        position: 'absolute' as 'absolute',
        height: '2rem',
        width: '16rem',
        left: 'calc(50% - 8rem)',
        background: 'white',
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
        top: '-0.5rem',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        margin: '0 auto',
        '& svg': {
            fontSize: '1.25rem',
            marginRight: '0.5rem',
        },
        '& p': {
            fontSize: '1rem',
            lineHeight: 1,
        },
    },
    marketCloseCountdown: {
        display: 'flex',
        alignItems: 'center',
    },
    marketCountdownTitle: {
        fontWeight: 600,
    },
    marketCountdownTooltip: {
        marginTop: '0.5rem',
        '&::before': {
            content: '""',
            borderStyle: 'solid',
            borderWidth: '0 1em 1em 1em',
            borderColor: `transparent transparent #333333 transparent`,
            display: 'block',
            zIndex: -1,
            marginTop: '-1em',
            position: 'absolute' as 'absolute',
            left: 'calc(50% - 1em)',
        },
    },
    tableWrapper: {
        overflowX: 'auto' as 'auto',
    },
    table: {
        minWidth: '750px',
    },
    emptyTableBody: {
        background: '#F6F6F6',
    },
    emptyTableInfoCell: {
        border: 'none',
        textAlign: 'center' as 'center',
        fontSize: '1.25rem',
    },
    emptyTableInfo: {
        background: 'white',
        width: '32rem',
        padding: '1rem',
        borderRadius: '0.3rem',
        margin: 'auto',
    },
    amountSliderContainer: {
        display: 'flex',
    },
    amountSlider: {
        alignSelf: 'center',
        margin: '0 2rem',
        '& .MuiSlider-valueLabel': {
            left: '-16px',
            top: '-62px',
            '&> span': {
                width: '52px',
                height: '52px',
            },
        },
        '&.Mui-disabled': {
            opacity: 0.5,
            '&> .MuiSlider-track': {
                backgroundColor: '#dfdfdf',
            },
            '&> .MuiSlider-thumb': {
                width: '20px',
                height: '20px',
                margin: '-8px 0 0 -10px',
            },
        },
    },
    daySliderInnerContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '0.5rem 0',
        width: '100%',
    },
    daySlider: {
        '&.Mui-disabled': {
            opacity: 0.5,
            '&> .MuiSlider-track': {
                backgroundColor: '#dfdfdf',
            },
            '&> .MuiSlider-thumb': {
                width: '20px',
                height: '20px',
                margin: '-8px 0 0 -10px',
            },
        },
    },
    daysExtendedMinMaxContainer: {
        userSelect: 'none' as 'none',
        textAlign: 'center' as 'center',
        color: '#757575',
        padding: '0 1.5rem',
    },
    daysExtendedMinMaxTitle: {
        fontSize: '0.7rem',
        fontWeight: 500,
    },
    daysExtendedMinMaxText: {
        lineHeight: 1,
        fontWeight: 500,
    },
    headerTextBox: {
        width: '12rem',
        height: '4rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        fontWeight: 500,
        color: '#757575',
    },
    aprInputContainer: {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        width: '12rem',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexShrink: 0,
    },
    aprInput: {
        '& input': {
            textAlign: 'center' as 'center',
        },
        height: '100%',
        width: '9rem',
    },
    aprInputButtonsContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'space-evenly',
        height: '100%',
    },
    aprInputButton: {
        minWidth: '1.25rem',
        width: '1.25rem',
        height: '1.25rem',
        padding: 0,
        '& svg': {
            width: '1rem',
            height: '1.25rem',
        },
        backgroundColor: '#f2f2f2',
    },
    confirmOfferContainer: {
        position: 'absolute' as 'absolute',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        width: 'calc(100% - 50px)',
        height: '8rem',
        background: 'white',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.35)',
        borderRadius: '5px',
    },
    offerConfigContainer: {
        position: 'absolute' as 'absolute',
        width: 'calc(100% - 50px)',
        height: '8rem',
        background: 'white',
        borderRadius: '5px',
    },
    confirmResultContainer: {
        display: 'grid',
        alignItems: 'center',
        columnGap: '5rem',
        gridTemplateColumns: '1fr 1fr 0.5fr 1fr',
        '& .MuiTypography-caption': {
            fontSize: '0.9rem',
            lineHeight: '1',
        },
        '& .MuiTypography-body1': {
            fontSize: '1.25rem',
            fontWeight: 600,
        },
    },
    offerDetailsContainer: {
        padding: '0 25px 16px',
        backgroundImage: 'linear-gradient(to top, #F6F6F6 75%, #243E74 75%)',
        position: 'relative' as 'relative',
        height: '9rem',
        '& .MuiTypography-body1': {
            fontFeatureSettings: `'tnum' 1`,
        },
    },
    includeAllInvoicesSwitchLabel: {
        userSelect: 'none' as 'none',
        margin: '0',
        padding: '0 10px 5px',
        width: '150px',
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
        flexShrink: 0,
        '&> .MuiFormControlLabel-label': {
            fontSize: '0.75rem',
            lineHeight: 1,
            textAlign: 'center' as 'center',
            fontWeight: 500,
            color: '#757575',
        },
    },
    includeAllInvoicesSwitchTooltip: {
        marginBottom: '0.5rem',
        '&::after': {
            content: '""',
            borderStyle: 'solid',
            borderWidth: '1em 1em 0 1em',
            borderColor: `#333333 transparent transparent transparent`,
            display: 'block',
            position: 'absolute' as 'absolute',
            left: 'calc(50% - 1em)',
        },
    },
    offerLivePulseContainer: {
        position: 'absolute' as 'absolute',
        bottom: 0,
        right: 0,
        background: '#589B3C',
        color: 'white',
        padding: '0.4rem 0.8rem',
        borderRadius: '5px 0 5px',
        fontSize: '0.75rem !important',
    },
    offerLivePulseIcon: {
        width: '0.5rem',
        height: '0.5rem',
        background: 'white',
        marginRight: '0.75rem',
        animation: '$pulse 1.5s infinite',
        display: 'inline-block',
        borderRadius: '50%',
    },
    '@keyframes pulse': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255, 255, 255, 0.6)',
        },
        '70%': {
            boxShadow: '0 0 0 10px rgba(255, 255, 255, 0)',
        },
        '100%': {
            boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
        },
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(90vh - 200px)',
    },
}));
