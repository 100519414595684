import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { EnhancedTableHeadProps, ColumnHeaders } from '../AwardHistory.schema';

export const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = props => {
    const classes = useStyles();
    const { onRequestSort, order, orderCol } = props;
    const createSortHandler = property => event => onRequestSort(event, property);
    const columnHeaders: ColumnHeaders[] = [
        { id: 'invoice_id', alignRight: false, label: 'Invoice ID' },
        { id: 'amount', alignRight: true, label: 'Net Amount' },
        { id: 'vat_amount', alignRight: true, label: 'VAT' },
        { id: 'amount_grossvat', alignRight: true, label: 'Gross Amount' },
        { id: 'payment_due_date', alignRight: true, label: 'Due Date' },
        { id: 'premium_amount', alignRight: true, label: 'Premium' },
        { id: 'new_gross_amount', alignRight: true, label: 'New Gross Amount' },
        { id: 'new_due_date', alignRight: true, label: 'New Due Date' },
    ];

    return (
        <TableHead>
            <TableRow>
                {columnHeaders.map(row => (
                    <TableCell
                        style={{ borderLeft: row.id === 'premium_amount' ? '1px solid rgba(0, 0, 0, 0.12)' : 'none' }}
                        className={classes.tableHeadCell}
                        key={row.id}
                        align={row.alignRight ? 'right' : 'left'}
                        sortDirection={orderCol === row.id ? order : false}>
                        <TableSortLabel active={orderCol === row.id} direction={order} onClick={createSortHandler(row.id)}>
                            {row.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

const useStyles = makeStyles(theme => ({
    tableHeadCell: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,

        '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
            color: '#333',
        },
    },
}));
