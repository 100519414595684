import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { EnhancedTableHeadProps, ColumnHeaders } from '../ActiveInvoices.schema';

const columnHeaders: ColumnHeaders[] = [
    { id: 'invoice_id', alignRight: false, disablePadding: true, label: 'Invoice ID' },
    { id: 'amount', alignRight: true, disablePadding: false, label: 'Net Amount' },
    { id: 'vat_amount', alignRight: true, disablePadding: false, label: 'VAT' },
    { id: 'amount_grossvat', alignRight: true, disablePadding: false, label: 'Gross Amount' },
    { id: 'payment_due_date', alignRight: true, disablePadding: false, label: 'Due Date' },
    { id: 'premium_amount', alignRight: true, disablePadding: false, label: 'Premium' },
    { id: 'new_gross_amount', alignRight: true, disablePadding: false, label: 'New Gross Amount' },
    { id: 'new_due_date', alignRight: true, disablePadding: false, label: 'New Due Date' },
];

export const EnhancedTableHead: React.FC<EnhancedTableHeadProps> = props => {
    const { userType, onSelectAllClick, onRequestSort, order, orderCol, numSelected, rowCount, offerConfirmed, flipped } = props;
    const classes = useStyles();

    const createSortHandler = (property: string) => (event: React.MouseEvent<HTMLElement>) => onRequestSort(event, property);

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={offerConfirmed || flipped || !rowCount || userType === 'Vendor'}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </TableCell>
                {columnHeaders.map(row => (
                    <TableCell
                        style={{
                            display: ['premium_amount', 'new_gross_amount', 'new_due_date'].includes(row.id) && numSelected === 0 ? 'none' : 'table-cell',
                            borderLeft: row.id === 'premium_amount' && numSelected > 0 ? '1px solid rgba(0, 0, 0, 0.12)' : '',
                        }}
                        className={classes.tableHeadCell}
                        key={row.id}
                        align={row.alignRight ? 'right' : 'left'}
                        padding={row.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderCol === row.id ? order : false}>
                        {['premium_amount', 'new_gross_amount', 'new_due_date'].includes(row.id) ? (
                            row.label
                        ) : (
                            <TableSortLabel disabled={!rowCount} active={orderCol === row.id} direction={order} onClick={createSortHandler(row.id)}>
                                {row.label}
                            </TableSortLabel>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
const useStyles = makeStyles(theme => ({
    tableHeadCell: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,

        '& .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
            color: '#333',
        },
    },
}));
