import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const updateMarketSettings = gql`
    mutation updateMarketSettings($company_id: String!, $max_ext: Int, $min_apr: Float, $next_clear_time: DateTimeScalar) {
        updateMarketSettings(company_id: $company_id, max_ext: $max_ext, min_apr: $min_apr, next_clear_time: $next_clear_time)
    }
`;

function useUpdateMarketSettings() {
    // Destructure the mutation
    const [UpdateMarketSettings] = useMutation(updateMarketSettings);
    // Return the value to continue using it as is in the other files
    return UpdateMarketSettings;
}

export { useUpdateMarketSettings };
