import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const acceptTermsConditions = gql`
    mutation acceptTermsConditions($uuid: ID!) {
        acceptTermsConditions(uuid: $uuid) {
            terms_conds_flag
        }
    }
`;

function useAcceptTermsConditions() {
    // Destructure the mutation
    const [AcceptTermsConditions] = useMutation(acceptTermsConditions);
    // Return the value to continue using it as is in the other files
    return AcceptTermsConditions;
}

export { useAcceptTermsConditions };
