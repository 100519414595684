import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const getCustomerInfoQuery = gql`
    query GetCustomerInfoById($company_id: String!) {
        customer(company_id: $company_id) {
            company_id
            next_clear_time
            days_extended_offer
            include_all_invoices
            max_ext
            min_apr
            apr_offer
            lock_apr
        }
        activeInvoices(company_id: $company_id) {
            invoice_id
            company_id
            division_id
            amount
            currency
            payment_due_date
            transaction_date
            posting_date
            voucher_id
            fiscal_year
            adj_invoice_id
            description
            vat_amount
            amount_grossvat
            vendor_name
            vendor_address
            vendor_tax_id
            local_currency_key
            transaction_type
            local_currency_rate
            local_currency_original_invoice_amount
            erp
            sap_company_code
            sap_reference_number
            sap_customer_name
            sap_tax_code
            sap_payment_term
            sap_payment_term_description
            created
            updated
            is_paid
            past_due
            new_due_date
            clear_time
            included
            apr_rate
            premium_amount
            new_gross_amount
            discount_rate
        }
    }
`;

// This is custom hook, react treats all the functions which starts with use as custom react hooks.
function useGetCustomerInfoQuery(variables) {
    // Destructure the mutation
    const { data } = useQuery(getCustomerInfoQuery, { variables });
    // Return the value to continue using it as is in the other files
    return data;
}

export { useGetCustomerInfoQuery };
