import React from 'react';
import Numeral from 'numeral';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import DoneIcon from '@material-ui/icons/DoneRounded';
import EditIcon from '@material-ui/icons/EditRounded';

import { EnhancedTableToolbarProps } from '../ActiveInvoices.schema';

export const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = props => {
    const {
        userType,
        numSelected,
        includeAllInvoices,
        selectedAmount,
        flipHeader,
        flipped,
        handleConfirm,
        offerConfirmed,
        handleModify,
        totalEligibleAmount,
        totalPremiumAmount,
    } = props;
    const classes = useToolbarStyles();

    return (
        <Toolbar className={classes.root}>
            <div>
                {((!offerConfirmed && numSelected === 0) || flipped) && (
                    <div className={classes.toolbarStatsInner}>
                        <Box textAlign="center">
                            <Typography color="inherit" variant="caption">
                                Eligible Invoices
                            </Typography>
                            <Typography>€{Numeral(totalEligibleAmount).format('#,##0.00')}</Typography>
                        </Box>
                    </div>
                )}
                {!flipped && !offerConfirmed && numSelected > 0 && (
                    <div className={classes.toolbarStatsInner}>
                        <Box textAlign="center">
                            <Typography color="inherit" variant="caption">
                                Selected Invoices
                            </Typography>
                            <Typography>€{Numeral(selectedAmount).format('#,##0.00')}</Typography>
                        </Box>
                        <Box textAlign="center">
                            <Typography color="inherit" variant="caption">
                                Total Premium
                            </Typography>
                            <Typography>€{Numeral(totalPremiumAmount).format('#,##0.00')}</Typography>
                        </Box>
                    </div>
                )}
            </div>
            <div className={classes.actions}>
                {flipped && !offerConfirmed && (
                    <span className={classes.confirmCancelButtonsContainer}>
                        <Button variant="contained" color="primary" className={classes.submitButton} onClick={handleConfirm}>
                            <DoneIcon className={classes.leftAlignedIcon} /> Confirm Offer
                        </Button>
                        <Button variant="contained" color="secondary" className={classes.submitButton} onClick={flipHeader}>
                            Cancel
                        </Button>
                    </span>
                )}
                {!flipped && (numSelected > 0 || includeAllInvoices) && !offerConfirmed && userType === 'Customer' && (
                    <span className={classes.confirmCancelButtonsContainer}>
                        <Button variant="contained" color="primary" className={classes.submitButton} onClick={flipHeader}>
                            Submit Offer
                        </Button>
                    </span>
                )}
                {offerConfirmed && userType === 'Customer' && (
                    <Button variant="contained" color="secondary" className={classes.submitButton} onClick={handleModify}>
                        <EditIcon className={classes.leftAlignedIcon} /> Modify Offer
                    </Button>
                )}
            </div>
        </Toolbar>
    );
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        backgroundColor: '#F6F6F6',
        height: '52px',
        minHeight: '52px',
        paddingBottom: '16px',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 0,
    },
    toolbarStatsInner: {
        display: 'flex',
        paddingTop: '6px',
        '&> div': {
            width: theme.spacing(16),
        },
        '& .MuiTypography-body1': {
            fontWeight: 600,
            fontFeatureSettings: `'tnum' 1`,
        },
    },
    submitButton: {
        marginRight: theme.spacing(2),
        height: '40px',
        alignSelf: 'center',
    },
    leftAlignedIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1rem',
    },
    confirmCancelButtonsContainer: {
        display: 'flex',
    },
}));
