import React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import { MarketStatProps } from '../VendorMarketSettings.schema';

export const MarketStat: React.FC<MarketStatProps> = props => {
    const { label, content } = props;
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <Typography variant="caption">{label}</Typography>
            <Typography className={classes.content}>{content}</Typography>
        </Box>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        textAlign: 'center',
        display: 'inline-block',
    },
    content: {
        fontSize: '0.75rem',
    },
}));
