import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const submitOffer = gql`
    mutation submitOffer($company_id: String!, $invoice_ids: [ID], $include_all_invoices: Boolean, $days_extended_offer: Int, $apr_offer: Float) {
        submitOffer(
            company_id: $company_id
            invoice_ids: $invoice_ids
            include_all_invoices: $include_all_invoices
            days_extended_offer: $days_extended_offer
            apr_offer: $apr_offer
        )
    }
`;

function useSubmitOffer() {
    // Destructure the mutation
    const [SubmitOffer] = useMutation(submitOffer);
    // Return the value to continue using it as is in the other files
    return SubmitOffer;
}

export { useSubmitOffer };
