import axios from 'axios';
import configValues from '../config';

const VERIFY_C2FO_AUTH_URL = configValues.backend.C2FOAuthUrl;

const GATEWAY_SERVER_URL = configValues.gateway;
const APP_URL = 'app-extend.c2fo.com';

const AUTH_TOKEN_KEY = 'userToken';
const USER_EMAIL_KEY = 'userEmail';
const USER_TYPE_KEY = 'userType';
const USER_DIVISION_KEY = 'userDivisionId';
const USER_COMPANY_KEY = 'userCompanyIdList';
const USER_ID_KEY = 'userId';
const USER_TERMS_CONDS_KEY = 'userTermsCondsFlag';

const setToken = token => localStorage.setItem(AUTH_TOKEN_KEY, token);
export const getToken = () => localStorage.getItem(AUTH_TOKEN_KEY);

const setUserId = authResponse => localStorage.setItem(USER_ID_KEY, authResponse.authResponseJson.payload.user.uuid);
export const getUserId = () => localStorage.getItem(USER_ID_KEY);

const setUserEmail = authResponse => localStorage.setItem(USER_EMAIL_KEY, authResponse.authResponseJson.payload.user.emailAddress);
export const getUserEmail = () => localStorage.getItem(USER_EMAIL_KEY);

const setUserType = authResponse => localStorage.setItem(USER_TYPE_KEY, authResponse.userType);
export const getUserType = () => localStorage.getItem(USER_TYPE_KEY);

const setUserDivisionId = authResponse => localStorage.setItem(USER_DIVISION_KEY, authResponse.userDivisionId);
export const getUserDivisionId = () => localStorage.getItem(USER_DIVISION_KEY);

const setUserCompanyIdList = authResponse => localStorage.setItem(USER_COMPANY_KEY, authResponse.userCompanyIdList);
export const getUserCompanyIdList = () => localStorage.getItem(USER_COMPANY_KEY)?.split(',');

export const setUserTermsCondsFlag = authResponse => localStorage.setItem(USER_TERMS_CONDS_KEY, authResponse.userTermsCondsFlag);
export const getUserTermsCondsFlag = () => localStorage.getItem(USER_TERMS_CONDS_KEY) === 'true';

const clearAuth = () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(USER_EMAIL_KEY);
    localStorage.removeItem(USER_TYPE_KEY);
    localStorage.removeItem(USER_DIVISION_KEY);
    localStorage.removeItem(USER_COMPANY_KEY);
    localStorage.removeItem(USER_ID_KEY);
    localStorage.removeItem(USER_TERMS_CONDS_KEY);
};

const verifyToken = async (token, isFreshToken) => {
    try {
        const response = await axios.post(VERIFY_C2FO_AUTH_URL, { token, appContext: APP_URL });
        if (response.data.error === 'NO_ARX_USER_RECORD') throw Error('This user does not have permission to access AR Extend');
        else if (response.data.error) throw Error(response.data.error);

        setToken(token);
        setUserId(response.data);
        setUserEmail(response.data);
        setUserType(response.data);
        setUserTermsCondsFlag(response.data);
        setUserDivisionId(response.data);
        setUserCompanyIdList(response.data);
        return true;
    } catch (error) {
        if (isFreshToken) {
            // Display error in Alert on LoginForm
            throw Error(error.message || 'Something went wrong');
        } else {
            // Remove existing invalid credentials, display LoginForm
            console.error('[C2FO AUTH]', error.message);
            clearAuth();
            return false;
        }
    }
};

export const logout = () => {
    clearAuth();
    window.location.href = '/';
};

export const verifyLogin = () => {
    const token = getToken();
    if (!token) return false;
    return verifyToken(token, false);
};

export const login = async data => {
    try {
        const response = await axios.post(`${GATEWAY_SERVER_URL}/api/auth/c2fo-user-auth`, data);
        return verifyToken(response.data.jwt, true);
    } catch (error) {
        if (error.response.status === 401) throw Error('Incorrect username or password');
        else throw Error(error.message || 'Something went wrong.');
    }
};

export const requestMagicLink = email => {
    console.log('Requesting magic link for:', email);
};
