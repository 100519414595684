import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const getVendorMarketsQuery = gql`
    query getVendorMarkets($division_id: String!) {
        vendorMarkets(division_id: $division_id) {
            company_id
            customer_name
            next_clear_time
            include_all_invoices
            max_ext
            days_extended_offer
            min_apr
            apr_offer
            total_eligible_count
            total_selected_count
            total_eligible_amount
            total_selected_amount
        }
    }
`;

// This is custom hook, react treats all the functions which starts with use as custom react hooks.
function useGetVendorMarketsQuery(variables) {
    // Destructure the mutation
    const { data } = useQuery(getVendorMarketsQuery, { variables });
    // Return the value to continue using it as is in the other files
    return data;
}

export { useGetVendorMarketsQuery };
