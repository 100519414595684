import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { orderBy } from 'lodash';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';

import { getUserDivisionId } from '../../services/AuthService';
import { useGetVendorMarketsQuery as getVendorMarkets } from '../../services/graphql';

import { MarketExpansionPanel } from './MarketExpansionPanel/MarketExpansionPanel';

import { Market } from '../../schemas/market.schema';

import borealisLogo from '../../assets/borealis-logo.svg';

const DEFAULT_ROWS_PER_PAGE = 10;

export const VendorMarketSettings: React.FC = props => {
    const navigate = useNavigate();
    const classes = useMarketSettingsStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [markets, setMarkets] = useState<Market[]>([]);
    const [sortProperty, setSort] = useState<string>('next_clear_time');
    const [selectedMarket, setSelectedMarket] = useState<string>('');
    const [order, setOrder] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_ROWS_PER_PAGE);
    const data = getVendorMarkets({
        division_id: getUserDivisionId(),
    });

    useEffect(() => {
        setIsLoading(true);
        if (data) {
            let markets: Market[] = data.vendorMarkets;
            markets = markets.map(market => {
                const m = { ...market };
                if (m.next_clear_time) m.next_clear_time = new Date(m.next_clear_time);
                m.total_premium_amount = ((m.apr_offer / 100) * m.total_selected_amount * m.days_extended_offer) / 360;
                return m;
            });
            setMarkets(orderBy(markets, [o => o.next_clear_time || ''], ['desc']));
            setIsLoading(false);
        }
    }, [data]);

    const navigateToMarket = companyId => navigate(`/vendor/${companyId}/invoices`);
    const navigateToHistory = companyId => navigate(`/vendor/${companyId}/history`);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const sortMarkets = property => event => {
        setPage(0);
        if (property === sortProperty) {
            setOrder(!order);
        } else {
            setSort(property);
            setOrder(true);
        }
    };

    const columnHeaders = [
        { id: 'customer_name', allowSorting: true, label: 'Customer Name', gridClass: 'columnTwo', subClass: 'textAlignLeft' },
        { id: 'total_eligible_amount', allowSorting: true, label: 'Eligible Invoices', gridClass: 'columnOne', subClass: '' },
        { id: 'total_selected_amount', allowSorting: true, label: 'Invoices Offered', gridClass: 'columnOne', subClass: '' },
        { id: 'days_extended_offer', allowSorting: true, label: 'Days Extended', gridClass: 'columnOne', subClass: '' },
        { id: 'apr_offer', allowSorting: true, label: 'APR', gridClass: 'apr', subClass: '' },
        { id: 'total_premium_amount', allowSorting: true, label: 'Total Premium', gridClass: 'columnOne', subClass: '' },
        { id: 'next_clear_time', allowSorting: true, label: 'Market Status', gridClass: 'columnOne', subClass: '' },
        { id: 'expand_icon', allowSorting: false, label: '', gridClass: 'statusIconItem', subClass: '' },
    ];

    const handleChange = newMarketId => {
        if (newMarketId === selectedMarket) setSelectedMarket('');
        else setSelectedMarket(newMarketId || '');
    };

    const createSkeletonRows = (rowCount: number) => {
        const skeletonRows: JSX.Element[] = [];
        for (let i = 0; i < rowCount; i++) skeletonRows.push(<Skeleton key={i} variant="rect" className={classes.skeletonRow} />);
        return skeletonRows;
    };

    return (
        <Box className={classes.vendorMarketRoot}>
            <Paper className={classes.paper}>
                <div className={classes.marketHeader}>
                    <img src={borealisLogo} alt="Vendor logo" className={classes.vendorLogo} />
                </div>

                <List component="nav" className={classes.list} aria-label="contacts">
                    <ListItem className={`${classes.listItem} + ${classes.listItemHead}`}>
                        <Grid container spacing={2} alignItems="center" className={classes.gridContainer}>
                            {columnHeaders.map(row => (
                                <Grid key={row.id} item className={`${classes[`${row.gridClass}`]} + ${classes[`${row.subClass}`]}`}>
                                    <TableSortLabel
                                        className={classes.headingLabel}
                                        active={sortProperty === row.id}
                                        direction={order ? 'desc' : 'asc'}
                                        disabled={!row.allowSorting}
                                        onClick={sortMarkets(row.id)}>
                                        {row.label}
                                    </TableSortLabel>
                                </Grid>
                            ))}
                        </Grid>
                    </ListItem>
                    {markets.length > 0 ? (
                        orderBy(markets, sortProperty, [order ? 'desc' : 'asc'])
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((market: any) => {
                                return (
                                    <ListItem className={classes.listItem} key={market.company_id}>
                                        <MarketExpansionPanel
                                            market={market}
                                            navigateToMarket={navigateToMarket}
                                            selectedMarket={selectedMarket}
                                            showMarketDetails={handleChange}
                                            navigateToHistory={navigateToHistory}
                                        />
                                    </ListItem>
                                );
                            })
                    ) : isLoading ? (
                        createSkeletonRows(DEFAULT_ROWS_PER_PAGE)
                    ) : (
                        <Typography className={classes.emptyTableInfo}>No markets are currently available.</Typography>
                    )}
                </List>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    count={markets.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

const useMarketSettingsStyles = makeStyles<Theme>(theme => ({
    vendorMarketRoot: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 34px)',
        padding: 0,
    },
    vendorLogo: {
        position: 'absolute',
        width: '225px',
        background: 'white',
        padding: '0.625rem',
        borderRadius: '5px',
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)',
        margin: theme.spacing(-1),
    },
    marketHeader: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '4px 4px 0 0',
        height: '5.75rem',
        display: 'flex',
    },
    paper: {
        width: '98%',
        maxWidth: '1200px',
        margin: '2em 0 4em 0',
        alignSelf: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px',
    },
    emptyTableInfo: {
        background: 'white',
        width: '32rem',
        padding: '1rem',
        borderRadius: '0.3rem',
        margin: 'auto',
    },
    skeletonRow: {
        margin: theme.spacing(1),
        height: '39px !important',
    },
    gridContainer: {
        flexWrap: 'nowrap',
        fontWeight: 600,
        color: '#333333',
        marginRight: theme.spacing(3.75),
    },
    columnOne: {
        maxWidth: '14.433333%',
        flexBasis: '14.433333%',
    },
    columnTwo: {
        maxWidth: '16.666667%',
        flexBasis: '16.666667%',
    },
    apr: {
        maxWidth: '8.433333%',
        flexBasis: '8.433333%',
    },
    statusIconItem: {
        width: theme.spacing(3.75),
    },
    list: {
        paddingBottom: 0,
    },
    listItem: {
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        lineHeight: 1,
        textAlign: 'center',
        padding: 0,
        '& .MuiTableSortLabel-icon': {
            position: 'absolute',
            right: '-27px',
        },
    },
    listItemHead: {
        padding: theme.spacing(2, 1.875),
        '&:hover': {
            cursor: 'pointer',
        },
    },
    headingLabel: {
        color: theme.palette.text.secondary,
        fontSize: '0.75rem',
        '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
            color: '#333',
        },
    },
    textAlignLeft: {
        textAlign: 'left',
    },
    loader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
}));
