// TODO: Copied this over from @c2fo/components until the material styles clash issue is resolved

import React from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import NotificationIcon from '@material-ui/icons/NotificationsActiveTwoTone';

import { IProps, IVariants } from './Alert.schema';

const variants: IVariants = {
    success: 'success',
    warning: 'warning',
    error: 'error',
    info: 'info',
};

export const Alert: React.FC<IProps> = ({ variant, children, onClose, open }) => {
    const classes = useStyles();

    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            <SnackbarContent
                className={classes[variant]}
                message={
                    <span id="client-alert" className={classes.message}>
                        <NotificationIcon className={classes.icon} />
                        {children}
                    </span>
                }
                action={[
                    <IconButton key="close" color="inherit" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );
};

const useStyles = makeStyles<Theme>(theme => ({
    [variants.success]: {
        backgroundColor: '#05B2DC',
    },
    [variants.warning]: {
        backgroundColor: '#F5A623',
    },
    [variants.error]: {
        backgroundColor: '#BB3100',
    },
    [variants.info]: {
        backgroundColor: '#333333',
    },
    icon: {
        fontSize: 24,
        marginRight: theme.spacing(3),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));
