import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import Numeral from 'numeral';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import ScheduleIcon from '@material-ui/icons/ScheduleRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

import { useUpdateMarketSettings as updateMarketSettingsMutation } from '../../../services/graphql';

import { MarketStat } from '../MarketStat/MarketStat';
import { AprMaskInput } from '../AprMaskInput/AprMaskInput';
import { useInterval } from '../../../services/CommonUtils';

import { MarketCardProps, DialogueInputProps } from '../VendorMarketSettings.schema';

export const MarketExpansionPanel: React.FC<MarketCardProps> = ({ market, navigateToMarket, selectedMarket, showMarketDetails, navigateToHistory }) => {
    const classes = useMarketCardStyles();
    const offerLive = !!market.days_extended_offer;
    const [marketCloseCountdown, setMarketCloseCountdown] = useState<string | false>();
    const [maxDaysExtended, setMaxDaysExtended] = useState<number>(market.max_ext);
    const [minApr, setMinApr] = useState<number>(market.min_apr);
    const [nextClearTime, setNextClearTime] = useState<Date>(market.next_clear_time);
    const [minDate, setMinDate] = useState<Date>();
    const [modifiedSettings, setModifiedSettings] = useState<boolean>(false);
    const [datePickerError, setDatePickerError] = useState<boolean>(false);
    const [marketClosed, setMarketClosed] = useState<boolean>(!market.next_clear_time);
    const [editMode, setEditMode] = useState<boolean>(false);
    const updateMarketSettings = updateMarketSettingsMutation();

    function calculateCountdown(endDate: Date) {
        let diff = (Date.parse(String(endDate)) - Date.parse(String(new Date()))) / 1000;
        if (!endDate || diff <= 0) return false;

        const timeLeft = { days: 0, hours: 0, min: 0 };

        if (diff >= 86400) {
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) {
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        return `${timeLeft.days}d ${timeLeft.hours}h ${timeLeft.min}m`;
    }

    useEffect(() => {
        function calculateMinDate() {
            const newMinDate = new Date();
            if (newMinDate.getUTCHours() >= 11) newMinDate.setDate(newMinDate.getDate() + 1);
            newMinDate.setUTCHours(11);
            newMinDate.setMinutes(0);
            newMinDate.setSeconds(0);
            return newMinDate;
        }

        setMinDate(calculateMinDate());
        setMarketCloseCountdown(calculateCountdown(market.next_clear_time));
    }, [market.next_clear_time]);

    useInterval(() => {
        setMarketCloseCountdown(calculateCountdown(market.next_clear_time));
    }, 1000);

    useEffect(() => {
        if (
            (!datePickerError || marketClosed) &&
            (market.max_ext !== maxDaysExtended ||
                market.min_apr !== minApr ||
                String(market.next_clear_time) !== String(nextClearTime) ||
                (marketClosed && !!market.next_clear_time))
        )
            setModifiedSettings(true);
        else setModifiedSettings(false);
    }, [datePickerError, marketClosed, maxDaysExtended, minApr, nextClearTime, market.max_ext, market.min_apr, market.next_clear_time]);

    const handleSaveSettings = () => {
        updateMarketSettings({
            variables: {
                company_id: market.company_id,
                max_ext: maxDaysExtended,
                min_apr: minApr,
                next_clear_time: marketClosed ? null : format(nextClearTime, 'yyyy-MM-dd HH:mm:ss xxxxx'),
            },
        });
        market.max_ext = maxDaysExtended;
        market.min_apr = minApr;
        market.next_clear_time = marketClosed ? null : nextClearTime;
        setEditMode(false);
    };

    const exitEditMode = () => {
        setEditMode(false);
        setNextClearTime(market.next_clear_time);
        setMinApr(market.min_apr);
        setMaxDaysExtended(market.max_ext);
        setMarketClosed(!market.next_clear_time);
    };

    const handleClearDateChange = (newClearDate: Date | null) => {
        if (newClearDate) {
            newClearDate.setUTCDate(newClearDate.getDate());
            newClearDate.setUTCHours(11);
            newClearDate.setMinutes(0);
            newClearDate.setSeconds(0);
            setNextClearTime(newClearDate);
        }
    };

    const handleSuspendMarketSwitch = () => {
        if (marketClosed && !nextClearTime && minDate) setNextClearTime(minDate);
        setMarketClosed(!marketClosed);
    };

    const handleClearDateChangeError = (error, value) => {
        if (error) setDatePickerError(true);
        else setDatePickerError(false);
    };

    const handleMinAprChange = event => setMinApr(+event.currentTarget.value);
    const handleMaxExtChange = event => setMaxDaysExtended(Math.abs(parseInt(event.currentTarget.value || 0, 10)));

    const handleCustomerNameClick = event => {
        event.preventDefault();
        navigateToMarket(market.company_id);
    };

    const statusIcon = () => {
        if (offerLive)
            return (
                <Tooltip title="Offer Live" placement="top">
                    <Box className={classes.offerLivePulseIcon}></Box>
                </Tooltip>
            );
        else return <Box className={classes.offerDisabledPulseIcon}></Box>;
    };

    return (
        <ExpansionPanel
            square={false}
            className={classes.expansionPanel}
            expanded={selectedMarket === market.company_id}
            onChange={() => showMarketDetails(market.company_id)}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionPanelSummary}>
                <Grid container spacing={2} alignItems="center" className={classes.gridContainer}>
                    <Grid item xs={2} className={classes.textAlignLeft}>
                        <Link
                            className={classes.customerName}
                            href="#"
                            color="secondary"
                            onClick={e => {
                                handleCustomerNameClick(e);
                            }}>
                            {market.customer_name}
                        </Link>
                        <br />
                        <Typography variant="caption" component="p" className={classes.companyId}>
                            {market.company_id}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        {`€${Numeral(market.total_eligible_amount).format('#,##0.00')}`}
                    </Grid>
                    <Grid item xs={1}>
                        {`€${Numeral(market.total_selected_amount).format('#,##0.00')}`}
                    </Grid>
                    <Grid item xs={1}>{`${market.days_extended_offer || '-'}`}</Grid>
                    <Grid item className={classes.apr}>
                        {offerLive ? `${market.apr_offer.toFixed(2)}%` : '-'}
                    </Grid>
                    <Grid item xs={1}>
                        {offerLive ? `€${Numeral(market.total_premium_amount).format('#,##0.00')}` : '-'}
                    </Grid>
                    <Grid item xs={1}>
                        {marketCloseCountdown ? (
                            <span>
                                <ScheduleIcon className={classes.scheduleIcon} /> {marketCloseCountdown}
                            </span>
                        ) : (
                            <Box>Closed</Box>
                        )}
                    </Grid>
                    <Grid item className={classes.statusIconItem}>
                        {statusIcon()}
                    </Grid>
                </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={`${classes.expansionPanelDetails} ${editMode && 'editMode'}`}>
                {!editMode && (
                    <Grid container spacing={2} alignItems="center" className={classes.gridContainer}>
                        <Grid item xs={2} className={classes.textAlignLeft}>
                            <MarketStat label="Minimum APR" content={`${market.min_apr}%`} />
                        </Grid>
                        <Grid item className={classes.columnOne}>
                            <MarketStat label="Maximum Extension" content={`${market.max_ext} Days`} />
                        </Grid>
                        <Grid item className={classes.actions}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={classes.button}
                                startIcon={<EditIcon />}
                                onClick={() => setEditMode(true)}>
                                Edit
                            </Button>
                            <Button
                                color="secondary"
                                size="small"
                                className={`${classes.button} ${classes.buttonLink} `}
                                onClick={() => navigateToHistory(market.company_id)}>
                                History
                            </Button>
                        </Grid>
                    </Grid>
                )}
                {editMode && (
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={2}>
                            <TextField
                                label="Min APR"
                                value={Numeral(minApr).format('0#.##')}
                                onChange={handleMinAprChange}
                                size="small"
                                variant="outlined"
                                fullWidth
                                className={classes.textField}
                                InputProps={
                                    {
                                        endAdornment: (
                                            <InputAdornment position="end" className={classes.inputAdornment}>
                                                %
                                            </InputAdornment>
                                        ),
                                        inputComponent: AprMaskInput,
                                    } as DialogueInputProps
                                }
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.ml3}>
                            <TextField
                                label="Max Extension"
                                value={Numeral(maxDaysExtended).format('#')}
                                onChange={handleMaxExtChange}
                                size="small"
                                fullWidth
                                variant="outlined"
                                className={classes.textField}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end" className={classes.inputAdornment}>
                                            Days
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.ml3}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    className={classes.datePicker}
                                    disabled={marketClosed}
                                    onError={handleClearDateChangeError}
                                    error={marketClosed ? false : !!datePickerError}
                                    disableToolbar
                                    autoOk
                                    minDate={minDate}
                                    format="yyyy/MM/dd"
                                    label="Next Clear Date"
                                    value={nextClearTime}
                                    onChange={handleClearDateChange}
                                    size="small"
                                    fullWidth
                                    inputVariant="outlined"
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel
                                className={classes.suspendMarketSwitchLabel}
                                onChange={handleSuspendMarketSwitch}
                                control={<Checkbox checked={marketClosed} color="secondary" />}
                                label="Suspend Market"
                            />
                        </Grid>
                        <Grid item className={`${classes.actions} + ${classes.editableActions}`}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                className={classes.button}
                                startIcon={<SaveIcon />}
                                onClick={handleSaveSettings}
                                disabled={!modifiedSettings}>
                                Save
                            </Button>
                            <Button
                                color="secondary"
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                startIcon={<CancelIcon />}
                                onClick={() => exitEditMode()}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
};
const useMarketCardStyles = makeStyles<Theme>(theme => ({
    expansionPanel: {
        width: '100%',
        borderRadius: 0,
        border: 0,
        '&:first-child, &:last-child': {
            border: 0,
            borderRadius: 0,
            boxShadow: 'none',
        },
        '&.Mui-expanded': {
            background: '#f1f1f1',
        },
    },
    expansionPanelSummary: {
        minHeight: 0,
        padding: theme.spacing(0, 2),
        '&.Mui-expanded': {
            minHeight: 0,
            borderBottom: '1px solid #e6e3e3',
            padding: 0,
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
        '& .MuiExpansionPanelSummary-content': {
            margin: theme.spacing(1, 0),
        },
    },
    expansionPanelDetails: {
        padding: theme.spacing(1, 2),
        marginRight: theme.spacing(4.5),
        '&.editMode': {
            paddingTop: theme.spacing(1.75),
        },
    },
    gridContainer: {
        flexWrap: 'nowrap',
        '& .MuiGrid-grid-xs-1': {
            maxWidth: '14.433333%',
            flexBasis: '14.433333%',
        },
    },
    columnOne: {
        maxWidth: '14.433333%',
        flexBasis: '14.433333%',
    },
    companyId: {
        marginTop: '2px',
    },
    customerName: {
        lineHeight: 1.3,
    },
    apr: {
        maxWidth: '8.433333%',
        flexBasis: '8.433333%',
    },
    statusIconItem: {
        width: theme.spacing(3.75),
    },
    scheduleIcon: {
        verticalAlign: 'text-bottom',
        fontSize: '1.05rem',
    },
    button: {
        marginRight: theme.spacing(0.875),
        fontSize: '0.7125rem',
        '& .MuiButton-startIcon': {
            marginRight: theme.spacing(0.75),
        },
        '& .MuiSvgIcon-root': {
            width: '0.77em',
            height: '0.77em',
        },
    },
    buttonLink: {
        '&:hover': {
            boxShadow: 'none',
        },
    },
    textAlignLeft: {
        textAlign: 'left',
    },
    textField: {
        '& input': {
            fontSize: '0.875rem',
        },
    },
    inputAdornment: {
        '& .MuiTypography-body1': {
            fontSize: '0.875rem',
        },
    },
    suspendMarketSwitchLabel: {
        marginLeft: theme.spacing(0.625),
        '& span': {
            fontSize: '0.875rem',
        },
    },
    actions: {
        flexGrow: 1,
        marginRight: theme.spacing(-5.625),
        textAlign: 'right',
    },
    editableActions: {
        marginTop: '5px',
    },
    offerLivePulseIcon: {
        width: theme.spacing(1),
        height: theme.spacing(1),
        background: theme.palette.primary.main,
        marginRight: theme.spacing(1.75),
        animation: '$pulse 1.5s infinite',
        display: 'inline-block',
        borderRadius: '50%',
        marginBottom: '1.5px',
    },
    '@keyframes pulse': {
        '0%': {
            boxShadow: `0 0 0 0 ${theme.palette.primary.main}60`,
        },
        '70%': {
            boxShadow: `0 0 0 10px ${theme.palette.primary.main}00`,
        },
        '100%': {
            boxShadow: `0 0 0 0 ${theme.palette.primary.main}00`,
        },
    },
    offerDisabledPulseIcon: {
        width: theme.spacing(1),
        height: theme.spacing(1),
        background: '#d6d1d1',
        marginRight: theme.spacing(1.75),
        display: 'inline-block',
        borderRadius: '50%',
        marginBottom: '1.5px',
    },
    datePicker: {
        '& .MuiOutlinedInput-adornedEnd': {
            paddingRight: 0,
            fontSize: '0.875rem',
        },
        '& .MuiSvgIcon-root': {
            width: '.8em',
            height: '.8em',
        },
        '& .MuiInputLabel-marginDense': {
            fontSize: '0.875rem',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            fontSize: '1rem',
        },
        '& legend': {
            fontSize: '0.75rem',
        },
    },
    ml3: {
        marginLeft: theme.spacing(3),
    },
}));
