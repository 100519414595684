import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import DescriptionIcon from '@material-ui/icons/Description';
import ExitIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/HomeRounded';
import HistoryIcon from '@material-ui/icons/RestorePageRounded';
import c2foLogo from '../../assets/c2fo-logo-white.svg';

import { logout, getUserTermsCondsFlag, getUserType, getUserCompanyIdList, getUserDivisionId } from '../../services/AuthService';

//GraphQL Service Import
import { useGetVendorMarketsQuery as getVendorMarkets, useGetCustomerMarketsQuery as getCustomerMarkets } from '../../services/graphql';

import { NavItem } from './NavItem/NavItem';

import { Market } from './NavDrawer.schema';

export const NavDrawer: React.FC = () => {
    const userCompanyIdList = getUserCompanyIdList();

    const data =
        getUserType() === 'Vendor'
            ? getVendorMarkets({
                  division_id: getUserDivisionId(),
              })
            : getCustomerMarkets({
                  company_ids: userCompanyIdList,
              });

    const classes = useNavDrawerStyles();
    const [markets, setMarkets] = useState<Market[]>();

    // Do not display Nav links if Customer user has not yet signed the Terms & Conditions
    const hideMarketList = getUserType() === 'Customer' && !getUserTermsCondsFlag();

    useEffect(() => {
        if (data) {
            const markets: Market[] = getUserType() === 'Vendor' ? data.vendorMarkets : data.customerMarkets;
            setMarkets(markets);
        }
    }, [data]);

    return (
        <Drawer variant="permanent" anchor="left">
            <List>
                <img src={c2foLogo} alt="C2FO logo" className={classes.c2foLogo} />
                <Typography className={classes.appTitle}>AR Extend</Typography>
                {!hideMarketList && (getUserType() === 'Vendor' || (markets && markets.length > 1)) && (
                    <Fragment>
                        <Divider className={classes.drawerDivider} />
                        <NavItem url="/vendor/markets" icon={<HomeIcon />} label="Markets" />
                    </Fragment>
                )}
                {!hideMarketList &&
                    getUserType() !== 'Vendor' &&
                    markets &&
                    markets.map(market => (
                        <Fragment key={market.company_id}>
                            <Divider className={classes.drawerDivider} />
                            <Typography className={classes.customerName}>{market.customer_name}</Typography>
                            <NavItem url={`/customer/${market.company_id}/invoices`} icon={<DescriptionIcon />} label="Invoices" />
                            <NavItem url={`/customer/${market.company_id}/history`} icon={<HistoryIcon />} label="History" />
                        </Fragment>
                    ))}
            </List>
            <List className={classes.logoutContainer}>
                <ListItem button onClick={logout}>
                    <ListItemIcon>
                        <ExitIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Drawer>
    );
};

const useNavDrawerStyles = makeStyles<Theme>(theme => ({
    c2foLogo: {
        width: '100px',
        padding: '0.5rem 0',
        alignSelf: 'center',
    },
    appTitle: {
        lineHeight: '1',
        marginBottom: theme.spacing(2),
        textAlign: 'center',
    },
    drawerDivider: {
        margin: theme.spacing(1, 0),
    },
    customerName: {
        textAlign: 'center',
        margin: theme.spacing(1, 0),
        fontSize: '0.9rem',
        fontWeight: 500,
        padding: theme.spacing(0, 1),
    },
    logoutContainer: {
        marginTop: 'auto',
    },
}));
