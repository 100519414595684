import React from 'react';
import MaskedInput from 'react-text-mask';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { AprMaskInputProps } from '../VendorMarketSettings.schema';

const aprMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    requireDecimal: true,
    integerLimit: 2,
    includeThousandsSeparator: false,
    allowLeadingZeroes: true,
});

export const AprMaskInput: React.FC<AprMaskInputProps> = ({ inputRef, ...other }) => (
    <MaskedInput
        {...other}
        ref={ref => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={aprMask}
        showMask
        placeholderChar={'\u2000'}
    />
);
