import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { MarketCard } from './MarketCard/MarketCard';

import { Market } from '../../schemas/market.schema';
import { getUserCompanyIdList } from '../../services/AuthService';
import { useGetCustomerMarketsQuery as getCustomerMarkets } from '../../services/graphql';
import { useNavigate } from 'react-router';

export const CustomerMarketSummary: React.FC = props => {
    const classes = useMarketSettingsStyles();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [markets, setMarkets] = useState<Market[]>([]);
    const userCompanyIdList = getUserCompanyIdList();

    const data = getCustomerMarkets({
        company_ids: userCompanyIdList,
    });

    useEffect(() => {
        setIsLoading(true);
        if (data) {
            // Sort live markets with offers first, closed markets last
            const market: Market[] = data.customerMarkets;
            setMarkets(orderBy(market, [o => o.next_clear_time || ''], ['desc']));
            setIsLoading(false);
        }
    }, [data]);

    const navigateToMarket = (companyId: string) => navigate(`/customer/${companyId}/invoices`);

    return (
        <Box className={classes.marketsRoot}>
            {markets.map((market: any) => (
                <MarketCard key={market.company_id} market={market} navigateToMarket={navigateToMarket} />
            ))}
        </Box>
    );
};

const useMarketSettingsStyles = makeStyles<Theme>(theme => ({
    marketsRoot: {
        minHeight: 'calc(100vh - 34px)',
        display: 'flex',
        flexDirection: 'column',
        '&> .MuiCard-root:first-of-type': {
            marginTop: theme.spacing(4),
        },
        '&> .MuiCard-root:last-of-type': {
            marginBottom: theme.spacing(6),
        },
    },
}));
