import React from 'react';

import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

import c2foLogo from '../../assets/c2fo-logo-white.svg';
import { LoginForm } from '../../components/';

export const Login: React.FC<{ handleLoginSuccess: Function }> = ({ handleLoginSuccess }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.logo}>
                <img src={c2foLogo} alt="C2FO logo" className={classes.c2foLogo} />
                <Typography className={classes.appTitle}>AR Extend</Typography>
            </div>
            <LoginForm onLoginSuccess={handleLoginSuccess} />
        </div>
    );
};

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
        marginTop: theme.spacing(8),
        maxWidth: theme.spacing(60),
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px',
        borderRadius: '5px',
    },
    logo: {
        display: 'inline',
        width: '100%',
        padding: theme.spacing(4),
        textAlign: 'center',
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '5px 5px 0 0',
    },
    c2foLogo: {
        width: '150px',
        alignSelf: 'center',
    },
    appTitle: {
        lineHeight: '1',
        textAlign: 'center',
        fontSize: theme.spacing(3),
        marginTop: theme.spacing(1),
    },
}));
