import React, { Fragment, useState } from 'react';
import { Form, FormSpy, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import { login, requestMagicLink } from '../../services/AuthService';
import { Alert } from '../Alert/Alert';

import { IProps, IFormValues } from './LoginForm.schema';

const SHOULD_REMEMBER_EMAIL_KEY: string = 'should_remember_email';
const EMAIL_KEY: string = 'email';

export const LoginForm: React.FC<IProps> = ({ onLoginSuccess }) => {
    const classes = useStyles();

    const initialUsername: string | null = localStorage.getItem(EMAIL_KEY);

    const [shouldRememberEmail, setShouldRememberEmail] = useState<boolean>(localStorage.getItem(SHOULD_REMEMBER_EMAIL_KEY) === 'true');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [showMagicLinkSection, setShowMagicLinkSection] = useState<boolean>(false);
    const [magicLinkDisabled, setMagicLinkDisabled] = useState<boolean>(false);
    const [emailValue, setEmailValue] = useState<string>('');

    const validate = (values: IFormValues) => {
        return {
            username: values.username ? undefined : true,
            password: values.password ? undefined : true,
        };
    };

    const onSubmit = async (values: IFormValues) => {
        setShowError(false);

        try {
            const response = await login(values);
            if (response) onLoginSuccess();
            if (shouldRememberEmail) localStorage.setItem(EMAIL_KEY, values.username);
        } catch (error) {
            setErrorMessage(error.message);
            setShowError(true);
            return { [FORM_ERROR]: error.message };
        }
    };

    const rememberEmailChanged = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setShouldRememberEmail(checked);
        localStorage.setItem(SHOULD_REMEMBER_EMAIL_KEY, checked ? 'true' : 'false');

        if (!checked) localStorage.removeItem(EMAIL_KEY);
    };

    const handleMagicLinkClick = () => {
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setShowError(false);
        if (!emailValue && emailValue.match(emailRegex)) {
            setMagicLinkDisabled(true);
            requestMagicLink(emailValue);
        } else {
            setErrorMessage('You must enter a valid email address before requesting a Magic Link');
            setShowError(true);
        }
    };

    return (
        <Fragment>
            <Form validate={validate} onSubmit={onSubmit}>
                {({ handleSubmit, submitting, values }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                        <Alert variant="error" open={showError} onClose={() => setShowError(false)}>
                            <div>{errorMessage}</div>
                        </Alert>

                        <Fragment>
                            <Field name="username" initialValue={initialUsername}>
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        error={meta.touched && meta.error}
                                        fullWidth
                                        label="Email"
                                        type="email"
                                        autoComplete="email"
                                        margin="normal"
                                        variant="outlined"
                                        autoFocus={!initialUsername}
                                    />
                                )}
                            </Field>

                            <Field name="password">
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        error={meta.touched && meta.error}
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        autoComplete="current-password"
                                        margin="normal"
                                        variant="outlined"
                                        autoFocus={!!initialUsername}
                                    />
                                )}
                            </Field>

                            <FormControlLabel
                                control={<Checkbox onChange={rememberEmailChanged} value="remember" color="primary" checked={shouldRememberEmail} />}
                                label="Remember email"
                            />
                        </Fragment>

                        <Button type="submit" variant="contained" color="primary" disabled={submitting} fullWidth size="large" className={classes.submit}>
                            SIGN IN
                        </Button>

                        <FormSpy
                            subscription={{ values: true }}
                            onChange={({ values }) => {
                                // TODO: Find a better way to watch this value (classic onChange field instead of react-final-form magic?)
                                setEmailValue(values.username);
                            }}
                        />
                    </form>
                )}
            </Form>

            {/* {showMagicLinkSection ? (
                <div className={classes.magicLinkContainer}>
                    <Divider className={classes.divider} />
                    <Typography variant="caption" className={classes.magicLinkText}>
                        We can email you a magic link so you can sign in without having to type your password.
                    </Typography>

                    <Button variant="contained" color="secondary" disabled={magicLinkDisabled} onClick={handleMagicLinkClick} fullWidth size="large">
                        {!magicLinkDisabled ? (
                            <Fragment>
                                <EmailIcon className={classes.leftAlignedIcon} /> EMAIL ME A MAGIC LINK
                            </Fragment>
                        ) : (
                            <Fragment>
                                <DoneIcon className={classes.leftAlignedIcon} /> SENT! CHECK YOUR EMAIL
                            </Fragment>
                        )}
                    </Button>
                </div>
            ) : (
                <Link href="#" className={classes.forgotPasswordLink} onClick={() => setShowMagicLinkSection(true)}>
                    Forgot your password?
                </Link>
            )} */}
        </Fragment>
    );
};

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%',
        padding: theme.spacing(5, 4),
    },
    submit: {
        marginTop: theme.spacing(3),
    },
    forgotPasswordLink: {
        color: theme.palette.secondary.main,
        marginBottom: theme.spacing(3),
    },
    magicLinkContainer: {
        padding: theme.spacing(0, 4, 4),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    magicLinkText: {
        marginBottom: theme.spacing(2),
        display: 'block',
    },
    leftAlignedIcon: {
        marginRight: theme.spacing(1),
    },
}));
