import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';

import { MarketStatProps } from '../CustomerMarketSummary.schema';

export const MarketStat: React.FC<MarketStatProps> = ({ disabled, label, content }) => (
    <Fragment>
        <Typography variant="caption">{label}</Typography>
        {disabled ? <Typography style={{ color: '#cccccc' }}>-</Typography> : <Typography>{content}</Typography>}
    </Fragment>
);
