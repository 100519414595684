import React from 'react';
import Numeral from 'numeral';

import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';

import { LoadAwareStat } from '../LoadAwareStat/LoadAwareStat';

import { EnhancedTableToolbarProps } from '../AwardHistory.schema';

export const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = props => {
    const classes = useToolbarStyles();
    const { isLoading, totalAwardedAmount, totalPremiumAmount, daysExtended, aprRate } = props;

    return (
        <Toolbar className={classes.root}>
            <div>
                <div className={classes.toolbarStatsInner}>
                    <LoadAwareStat title="Original Amount" isLoading={isLoading}>
                        €{Numeral(totalAwardedAmount).format('#,##0.00')}
                    </LoadAwareStat>
                    <LoadAwareStat title="Days Extended" isLoading={isLoading}>
                        {daysExtended}
                    </LoadAwareStat>
                    <LoadAwareStat title="APR" isLoading={isLoading}>
                        {Numeral(aprRate).format('0.00')}%
                    </LoadAwareStat>
                    <LoadAwareStat title="Premium" isLoading={isLoading}>
                        €{Numeral(totalPremiumAmount).format('#,##0.00')}
                    </LoadAwareStat>
                    <LoadAwareStat title="New Total Amount" isLoading={isLoading}>
                        €{Numeral(totalAwardedAmount + totalPremiumAmount).format('#,##0.00')}
                    </LoadAwareStat>
                </div>
            </div>
        </Toolbar>
    );
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        backgroundColor: '#F6F6F6',
        height: '52px',
        minHeight: '52px',
        paddingBottom: '16px',
    },
    toolbarStatsInner: {
        display: 'flex',
        paddingTop: '6px',
        '& .MuiTypography-body1': {
            fontWeight: '600',
            fontFeatureSettings: `'tnum' 1`,
        },
    },
    skeletonStat: {
        margin: theme.spacing(0.5, 2),
        height: theme.spacing(2),
    },
}));
