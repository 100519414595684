import React, { useState, useEffect } from 'react';
import { uniq } from 'lodash';
import { CSVLink } from 'react-csv';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/CloudDownloadRounded';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import borealisLogo from '../../assets/borealis-logo.svg';
import { useGetAwardHistoryQuery as getAwardHistory } from '../../services/graphql';

import { EnhancedTable } from './EnhancedTable/EnhancedTable';

import { HistoryMenuProps, AwardCSVHeaders } from './AwardHistory.schema';
import { AwardHistory } from '../../schemas/awardHistory.schema';
import { useParams } from 'react-router';

const awardCSVHeaders: AwardCSVHeaders[] = [
    { label: 'vendor_name', key: 'vendor_name' },
    { label: 'vendor_id', key: 'sap_company_code' },
    { label: 'invoice_id', key: 'invoice_id' },
    { label: 'market_clear_date', key: 'clear_time' },
    { label: 'original_due_date', key: 'payment_due_date' },
    { label: 'days_extended', key: 'days_extended' },
    { label: 'new_due_date', key: 'new_due_date' },
    { label: 'original_net_amount', key: 'amount' },
    { label: 'original_vat_amount', key: 'vat_amount' },
    { label: 'original_gross_amount', key: 'amount_grossvat' },
    { label: 'apr_percentage', key: 'apr_rate' },
    { label: 'discount_percentage', key: 'discount_rate' },
    { label: 'premium_amount', key: 'premium_amount' },
    { label: 'new_gross_amount', key: 'new_gross_amount' },
    { label: 'currency', key: 'currency' },
];

export const History: React.FC = props => {
    const classes = useHistoryStyles();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [awardHistory, setAwardHistory] = useState<AwardHistory[]>();
    const [awardDates, setAwardDates] = useState<string[]>(['']);
    const [selectedAwardDate, setSelectedAwardDate] = useState<string>('');
    const [awardedInvoices, setAwardedInvoices] = useState<AwardHistory[]>([]);
    const [totalAwardedAmount, setTotalAwardedAmount] = useState<number>(0);
    const [totalPremiumAmount, setTotalPremiumAmount] = useState<number>(0);
    const params = useParams();

    const data = getAwardHistory({
        company_id: params.companyId,
    });

    useEffect(() => {
        setIsLoading(true);
        if (data) {
            const awardHistory: AwardHistory[] = data.awardHistory.map((invoice: AwardHistory) => {
                const new_due_date = new Date(invoice.new_due_date);
                const payment_due_date = new Date(invoice.payment_due_date);
                const days_extended = Math.floor(
                    (Date.UTC(new_due_date.getFullYear(), new_due_date.getMonth(), new_due_date.getDate()) -
                        Date.UTC(payment_due_date.getFullYear(), payment_due_date.getMonth(), payment_due_date.getDate())) /
                        (1000 * 60 * 60 * 24)
                );
                return {
                    ...invoice,
                    days_extended,
                    payment_due_date: new Date(invoice.payment_due_date).toISOString().split('T')[0],
                    new_due_date: new Date(invoice.new_due_date).toISOString().split('T')[0],
                    clear_time: new Date(invoice.clear_time).toISOString().split('T')[0],
                };
            });
            setAwardHistory(awardHistory);
            const uniqueAwardDates = uniq(awardHistory.map(item => item.clear_time));
            if (uniqueAwardDates.length) {
                setAwardDates(uniqueAwardDates);
                setSelectedAwardDate(uniqueAwardDates[0]);
            } else setSelectedAwardDate('');
            setIsLoading(false);
        }
    }, [data]);

    useEffect(
        function updateAwardTableData() {
            if (!selectedAwardDate) return;
            const filteredHistory = awardHistory?.filter(item => item.clear_time === selectedAwardDate) ?? [];
            setAwardedInvoices(filteredHistory);
            setTotalAwardedAmount(filteredHistory.reduce((total, row) => total + row.amount_grossvat, 0));
            setTotalPremiumAmount(filteredHistory.reduce((total, row) => total + row.premium_amount, 0));
        },
        [selectedAwardDate, awardHistory]
    );

    return (
        <Box className={classes.awardHistoryRoot}>
            <Paper className={classes.paper}>
                <div className={classes.marketHeader}>
                    <img src={borealisLogo} alt="Vendor logo" className={classes.vendorLogo} />
                </div>
                <Box className={classes.cardsContainer}>
                    <Box className={classes.awardCard}>
                        <Typography variant="caption">Award Date</Typography>
                        <Select
                            input={<OutlinedInput className={classes.awardDateSelect} labelWidth={0} />}
                            MenuProps={{ classes: { list: classes.awardDateSelectMenu } } as HistoryMenuProps}
                            value={selectedAwardDate}
                            disabled={!selectedAwardDate}
                            onChange={value => setSelectedAwardDate(value.target.value as string)}>
                            {awardDates.map((awardDate: string) => (
                                <MenuItem key={awardDate} value={awardDate}>
                                    {awardDate}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box className={classes.awardCard}>
                        <Typography variant="caption">Download Award File</Typography>
                        <Box className={classes.downloadButtonsContainer}>
                            {selectedAwardDate ? (
                                <CSVLink
                                    headers={awardCSVHeaders}
                                    data={awardedInvoices}
                                    filename={`borealis-award-${new Date(selectedAwardDate).toISOString().slice(0, 10)}.csv`}>
                                    <Button variant="contained" color="secondary" className={classes.downloadButton}>
                                        <DownloadIcon className={classes.leftAlignedIcon} /> CSV
                                    </Button>
                                </CSVLink>
                            ) : (
                                <Button variant="contained" disabled className={classes.downloadButton}>
                                    <DownloadIcon className={classes.leftAlignedIcon} /> CSV
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
                <EnhancedTable
                    isLoading={isLoading}
                    awardedInvoices={awardedInvoices}
                    totalAwardedAmount={totalAwardedAmount}
                    totalPremiumAmount={totalPremiumAmount}
                />
            </Paper>
        </Box>
    );
};

const useHistoryStyles = makeStyles(theme => ({
    awardHistoryRoot: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 34px)',
    },
    skeleton: {
        alignSelf: 'center',
    },
    paper: {
        width: '98%',
        maxWidth: '1200px',
        margin: '2em 0 4em 0',
        alignSelf: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px',
    },
    vendorLogo: {
        position: 'absolute',
        width: '225px',
        background: 'white',
        padding: '0.625rem',
        borderRadius: '5px',
        boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.15)',
        margin: theme.spacing(-1),
    },
    marketHeader: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '4px 4px 0 0',
        height: '5.75rem',
        display: 'flex',
    },
    awardCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: theme.spacing(32),
        height: theme.spacing(16),
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.35)',
        padding: theme.spacing(2, 3),
        '&:first-of-type': {
            justifySelf: 'end',
        },
        '& .MuiTypography-caption': {
            fontSize: '0.9rem',
            lineHeight: '1',
            marginBottom: theme.spacing(2),
            textAlign: 'center',
        },
    },
    awardDateSelect: {
        '& .MuiSelect-select': {
            lineHeight: 1,
            padding: theme.spacing(1.5, 3, 1.5, 2),
            textAlign: 'center',
        },
    },
    awardDateSelectMenu: {
        fontFeatureSettings: `'tnum' 1`,
        '&> li': {
            justifyContent: 'center',
        },
    },
    cardsContainer: {
        backgroundImage: 'linear-gradient(to top, #F6F6F6 75%, #243E74 75%)',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(6),
        paddingBottom: theme.spacing(2),
    },
    leftAlignedIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1rem',
    },
    downloadButtonsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        '&> a': {
            textDecoration: 'none',
        },
    },
    downloadButton: {
        height: '40px',
        alignSelf: 'center',
    },
}));
